import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import qs from "qs";
import { generateFilter, generateSort } from "../../../helpers";

export const usersDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({
      resource,
      pagination,
      sort,
      filters,
      metaData,
      hasPagination = true,
    }) => {
      const url = `${apiUrl}/${resource}`;
      const current = pagination?.current || 1;
      const pageSize = pagination?.pageSize || 10;

      const offset = pageSize * (current - 1);
      const max = pageSize;

      const { _sort, _order } = generateSort(sort);
      const queryFilters = generateFilter(filters);

      const query = {
        ...(hasPagination
          ? {
              max,
              offset,
            }
          : {}),
        ...(sort?.length
          ? {
              sort: _sort.join(","),
              order: _order.join(","),
            }
          : {}),
      };

      const filterQuery = qs.stringify(queryFilters);
      try {
        const { data, headers } = await httpClient.get(
          `${url}?${qs.stringify({ ...query, ...queryFilters })}`
        );
        return {
          data: data.rows,
          total: data.total,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    getOne: async ({ resource, id, meta }) => {
      const url = `${apiUrl}/${resource}/${id}`;
      try {
        const { data } = await httpClient.get(url);

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    update: async ({ resource, id, variables }) => {
      const url = `${apiUrl}/${resource}/${id}`;
      try {
        const { data } = await httpClient.put(url, { ...variables });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    create: async ({ resource, variables, metaData }) => {
      const url = `${apiUrl}/${resource}`;
      try {
        await httpClient.post(url, {
          ...variables,
        });

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({ resource, id }) => {
      const url = `${apiUrl}/${resource}/${id}`;

      try {
        const { data } = await httpClient.delete(url);
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
