import { useMatch } from "react-router-dom";
import { List, TextField, useTable } from "@refinedev/antd";
import { Space, Table } from "antd";
import s from "../List/Russtat-List.module.scss";
import { v4 as randomId } from "uuid";
import { useEffect, useState } from "react";
import { AppTitle } from "../../../../components";
import { useNavigation } from "@refinedev/core";
import { MapPlayers } from "./Map-Players/Map-Players";
import { MapAutoPlayers } from "./Map-Auto-Players/Map-Auto-Players";

export type RusstatPlayersRowType = {
  fnlLeagueId: number;
  fnlTeamId: number;
  fnlTeamTitle: string;
  fnlPlayerId: number;
  fnlPlayerName: string;
  rsPlayerId: number;
  rsPlayerName: string;
  isSaved: boolean;
};

type RusstatPlayersType = {
  rsPlayerId: number;
  rsPlayerName: string;
  rsTeamTitle: string;
};

export const PlayersList = () => {
  const { push } = useNavigation();
  const route = useMatch(
    "/:parent/:russtat/:clubs/:fnlLeagueId/:fnlSeasonId/:fnlTeamId"
  );
  const [datasource, setDatasource] = useState<RusstatPlayersRowType[]>([]);
  const [rsPlayers, setRsPlayers] = useState<RusstatPlayersType[]>([]);

  const { fnlLeagueId, fnlSeasonId, fnlTeamId } = route!.params;
  const { tableQueryResult, searchFormProps, setFilters } = useTable<any>({
    dataProviderName: "mappingRusstatPlayersDataProvider",
    resource: "russtat-players",
    queryOptions: {
      enabled: false,
    },
    syncWithLocation: false,
    filters: {
      initial: [
        {
          field: "fnlLeagueId",
          value: fnlLeagueId,
          operator: "null",
        },
        {
          field: "fnlSeasonId",
          value: fnlSeasonId,
          operator: "null",
        },
        {
          field: "fnlTeamId",
          value: fnlTeamId,
          operator: "null",
        },
      ],
    },
  });

  const fetchData = async () => {
    const { data } = await tableQueryResult.refetch();
    const clubs = data?.data as RusstatPlayersRowType[];

    if (clubs) {
      await setDatasource(clubs);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fnlLeagueId]);

  const playersTeam = `${datasource[0]?.fnlTeamTitle || ""}`;

  const resultPlayers = datasource.filter(
    (player) => player.fnlPlayerId && player.rsPlayerId && !player.isSaved
  );

  return (
    <List
      headerButtons={
        <MapAutoPlayers
          players={resultPlayers}
          refetchList={fetchData}
          isTable={false}
        />
      }
      breadcrumb={false}
      title={
        <>
          <AppTitle isLoading={tableQueryResult.isFetching} canGoBack>
            Игроки {playersTeam}
          </AppTitle>
          <div
            style={{
              color: "grey",
              fontSize: 12,
              padding: 16,
              fontWeight: 400,
              lineHeight: 2,
            }}
          >
            {`Выбрать команду без маппинга (Выделена красным) → 
              Нажать кнопку "Смапить игрока" → 
              Ввести часть фамилии/имени игрока (минимум три символа) → 
              Нажать кнопку "Искать игрока" → 
            `}
            <br />
            {`Найти в списке соответствующее имя игрока в Рустат → 
            Нажать кнопку "Смапить игрока" `}
          </div>
        </>
      }
    >
      <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
        <Table<RusstatPlayersRowType>
          dataSource={datasource}
          rowClassName={(record, index, indent) =>
            !record.isSaved ? s.needMapping : ""
          }
          pagination={false}
          loading={tableQueryResult.isFetching}
          rowKey={() => randomId()}
          locale={{
            emptyText: <div style={{ margin: 56 }}>Команды не найдены</div>,
          }}
          showSorterTooltip={false}
        >
          {/*<Table.Column<RusstatPlayersRowType>*/}
          {/*  width={100}*/}
          {/*  title="Команда"*/}
          {/*  render={(_, record) => (*/}
          {/*    <TextField value={record.fnlTeamTitle || "–"} />*/}
          {/*  )}*/}
          {/*/>*/}
          <Table.Column<RusstatPlayersRowType>
            width={100}
            title="Имя игрока FNL"
            render={(_, record) => (
              <TextField value={record.fnlPlayerName || "–"} />
            )}
          />
          <Table.Column<RusstatPlayersRowType>
            width={100}
            title="Имя игрока Рустат"
            render={(_, record) => (
              <TextField value={record.rsPlayerName || "–"} />
            )}
          />
          <Table.Column<RusstatPlayersRowType>
            width={40}
            title={"Действие"}
            align={"center"}
            render={(_, record) => {
              const isShowAutoMap =
                record.fnlPlayerId && record.rsPlayerId && !record.isSaved;

              return (
                <Space direction={"vertical"} size={8}>
                  <MapPlayers
                    refetchList={fetchData}
                    rsPlayers={rsPlayers}
                    fnlLeagueId={record.fnlLeagueId}
                    fnlPlayerId={record.fnlPlayerId}
                  />
                  {isShowAutoMap && (
                    <MapAutoPlayers
                      isTable={true}
                      refetchList={fetchData}
                      players={[record]}
                    />
                  )}
                </Space>
              );
            }}
          />
        </Table>
      </Space>
    </List>
  );
};
