export const richTextTemplate = {
  default: [
    ["undo", "redo"],
    ["font", "fontSize", "formatBlock"],
    ["bold", "underline", "italic", "strike", "subscript", "superscript"],
    ["fontColor", "hiliteColor", "textStyle"],
    ["removeFormat"],
    ["outdent", "indent"],
    ["align", "horizontalRule", "list"],
    ["table", "link", "image", "video"],
    ["fullScreen", "showBlocks", "codeView"],
  ],
  backofficeNews: [
    ["undo", "redo"],
    ["fontSize", "formatBlock", "fontColor"],
    ["align", "horizontalRule", "list"],
    ["link"],
    ["bold", "underline", "italic"],
  ],
};
