import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";

import { DataProvider } from "@refinedev/core";
import axAPI from "../../../../api/axios-instance";

export const mappingAutoRusstatPlayersDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    create: async ({
      resource,
      variables,
    }: {
      resource: string;
      variables: {
        fnlLeagueId: number;
        fnlPlayerId: number;
        rsPlayerId: number;
      }[];
    }) => {
      const url = "cms/rs/player/mapping/list";
      try {
        await httpClient.post(url, variables);

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
