import { Route } from "react-router-dom";
import { ShowProvider } from "../../../providers";

import { UsersImportContent } from "../UsersImportContent/Users-Import-Content";

const usersImportRoutes = [
  <Route
    key={"usersimport"}
    path="usersimport"
    element={
      <ShowProvider>
        <UsersImportContent />
      </ShowProvider>
    }
  />,
];

export const USERS_IMPORT_ROUTES = usersImportRoutes.map((route) => route);
