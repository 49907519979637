import { Button, Form, Modal, Select, Space } from "antd";
import { useForm, useModal } from "@refinedev/antd";
import { errorNotification, successNotification } from "../../../../../helpers";
import s from "./Map-Season.module.scss";

type MapTeamProps = {
  fnlLeagueId: number;
  fnlSeasonId: number;
  fnlTeamId: number;
  refetchList: () => void;
  rsClubs: { teamId: number; teamTitle: string }[];
};

export const MapClubs = (clubs: MapTeamProps) => {
  const { modalProps, show, close } = useModal();

  const { formProps, saveButtonProps, form, formLoading, onFinish } = useForm<{
    url: string;
  }>({
    resource: "russtat-clubs",
    meta: {
      ...clubs,
    },
    dataProviderName: "mappingRusstatClubsDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: "Маппинг прошел успешно",
        type: "success",
      },
      callback: () => {
        close();
        clubs.refetchList();
      },
    }),
    ...errorNotification({}),
  });

  return (
    <>
      <Button size="small" onClick={show} block>
        Смапить команду
      </Button>
      <Modal
        okText={"Подтвердить"}
        cancelText={"Отмена"}
        title={"Подтвердить действие"}
        onCancel={close}
        footer={null}
        {...modalProps}
      >
        <Form {...formProps} layout={"vertical"} className={s.form}>
          <Form.Item
            name={"rsTeamId"}
            rules={[
              {
                required: true,
                message: "Необходимо выбрать команду",
              },
            ]}
          >
            <Select
              showArrow={true}
              placeholder="Выберите команду Рустат"
              allowClear
              showSearch
              optionFilterProp={"label"}
            >
              {clubs.rsClubs?.map((select) => {
                return (
                  <Select.Option
                    key={select.teamId}
                    value={select.teamId}
                    label={select.teamTitle}
                  >
                    {select.teamTitle}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Space direction={"horizontal"} className={s.formFooter}>
            <Button type={"primary"} htmlType={"submit"}>
              Смапить команду
            </Button>
            <Button onClick={close}>Отмена</Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
};
