import React, { ReactNode } from "react";
import s from "./app-view-card.module.scss";
import cn from "classnames";

interface IAppViewCardProps {
  label: ReactNode;
  children: ReactNode;
  sharedStyles?: string;
}

export const AppViewCard: React.FC<IAppViewCardProps> = ({
  label,
  children,
  sharedStyles,
}) => {
  return (
    <div className={cn(s.card, sharedStyles)}>
      <span className={s.card__label}>{label}</span>
      {children}
    </div>
  );
};
