import React, { ReactNode } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { TitleProps } from "antd/lib/typography/Title";

import s from "./app-title.module.scss";
import { Spin, Typography } from "antd";
import { useNavigation } from "@refinedev/core";

const { Title } = Typography;

interface IAppTitleProps {
  children: ReactNode;
  isLoading?: boolean;
  titleProps?: TitleProps;
  canGoBack?: boolean;
  routerLink?: string;
}

export const AppTitle: React.FC<IAppTitleProps> = ({
  children,
  isLoading,
  titleProps,
  canGoBack,
  routerLink,
}) => {
  const { goBack, push } = useNavigation();

  const handleNavigate = () => {
    if (!routerLink) {
      goBack();
    } else {
      push(routerLink);
    }
  };

  const renderBack = () => {
    if (canGoBack) {
      return (
        <div onClick={handleNavigate} className={s.arrowContainer}>
          <ArrowLeftOutlined className={s.arrowIcon} />
        </div>
      );
    }
  };

  if (isLoading) {
    return <Spin size={"small"} />;
  }

  return (
    <Title className={s.title} level={4} {...titleProps}>
      {renderBack()}
      {children}
    </Title>
  );
};
