import { ResourceProps } from "@refinedev/core";
import { NumberOutlined } from "@ant-design/icons";

export const hashTagsResource: ResourceProps = {
  name: "hashtags",
  meta: {
    icon: <NumberOutlined />,
    label: "Хэштеги",
  },
  list: "/hash-tags",
};
