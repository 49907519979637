import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import { dateFormat, generateFilter } from "../../../helpers";
import qs from "qs";
import { NewsCreateRequest } from "../Lib/types";
import dayjs from "dayjs";

export const newsDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({
      resource,
      pagination,
      filters,
      meta,
      hasPagination = true,
    }) => {
      const url = `/cms/${resource}`;
      const current = pagination?.current || 1;
      const pageSize = pagination?.pageSize || 10;

      const offset = pageSize * (current - 1);
      const limit = pageSize;

      const queryFilters = generateFilter(filters);

      const query = {
        ...(resource === "news"
          ? {
              limit,
              offset,
            }
          : {}),
      };

      try {
        const { data, headers } = await httpClient.get(
          `${url}?${qs.stringify({ ...query, ...queryFilters })}`
        );

        return {
          data: {
            news: data.news,
            mainNews: data.mainNews,
          },
          total: data.total,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    getOne: async ({ resource, id }) => {
      const url = "/cms/news/info";
      try {
        const { data: response } = await httpClient.get(
          `${url}?${qs.stringify({ id })}`
        );
        const data = await { ...response, newsDate: dayjs(response.newsDate) };
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    update: async ({
      resource,
      id,
      variables,
    }: {
      id: string;
      resource: string;
      variables: NewsCreateRequest;
    }) => {
      const url = "/cms/news/update";
      try {
        const { data } = await httpClient.post(url, {
          ...variables,
          leagueId: variables.leagueId === 0 ? null : variables.leagueId,
          id,
          newsDate: dateFormat(
            variables.newsDate,
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          ),
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    create: async ({
      resource,
      variables,
    }: {
      resource: string;
      variables: NewsCreateRequest;
    }) => {
      const url = "cms/news/add";
      try {
        await httpClient.post(url, {
          ...variables,
          leagueId: variables.leagueId === 0 ? null : variables.leagueId,
          hashTags: variables.hashTags || [],
          preview: variables.preview || "",
          newsDate: dateFormat(
            variables.newsDate,
            "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
          ),
        });

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      resource,
      id,
      meta,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
      };
    }) => {
      const url = "/cms/news/remove";
      try {
        const { data } = await httpClient.post(
          `${url}?${qs.stringify({ id: meta.id })}`
        );
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
