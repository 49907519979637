import { DiffOutlined } from "@ant-design/icons";
import { ResourceProps } from "@refinedev/core";

export const mappingResource: ResourceProps = {
  name: "mapping",
  meta: {
    icon: <DiffOutlined />,
    label: "Маппинг",
  },
};

export const melbetResource: ResourceProps = {
  name: "melbet",
  list: "/mapping/melbet",
  meta: {
    parent: "mapping",
    label: "Мелбет",
  },
};

export const rusStatResource: ResourceProps = {
  name: "russtat",
  list: "/mapping/russtat",
  meta: {
    parent: "mapping",
    label: "Рустат",
  },
};

export const rusStatClubsResource: ResourceProps = {
  name: "russtat-clubs",
  list: "/mapping/russtat/clubs/:fnlLeagueId/:fnlSeasonId",
  meta: {
    parent: "russtat",
    hide: true,
  },
};

export const rusStatPlayersResource: ResourceProps = {
  name: "russtat-players",
  list: "/mapping/russtat/clubs/:fnlLeagueId/:fnlSeasonId/:fnlTeamId",
  meta: {
    parent: "russtat",
    hide: true,
  },
};
