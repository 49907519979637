import dayjs, { Dayjs } from "dayjs";

import customParseFormat from "dayjs/plugin/customParseFormat.js";

dayjs.extend(customParseFormat);

export const hoursAndMinutes = "HH:mm";
export const DayMonthYear = "DD.MM.YYYY";
export const DayMonthYearHoursAndMinutes = "DD.MM.YYYY HH:mm";
export const LocalUTCFormat = "YYYY-MM-DDTHH:mm:ss.SSS";
export const LocalUTCDayMonthYear = "YYYY-MM-DDT.SSS";

// создание объекта dayjs с текущей датой
export const today = dayjs();

// преобразование dayjs объекта в экземпляр Date
export const todayDate = today.toDate();

export const getTimezoneValue = (
  date: Date,
  timeZone?: string,
  format?: string
) => {
  if (!date) {
    return date;
  } else {
    return timeZone
      ? dayjs(date).tz(timeZone).format(format)
      : dayjs().format(format);
  }
};

export const disablePreviousDates = (
  currentDate: Dayjs | null,
  timeZone?: string | undefined
): boolean => {
  if (timeZone) {
    return currentDate
      ? currentDate.isBefore(dayjs().tz(timeZone).startOf("day"))
      : false;
  }
  return currentDate ? currentDate.isBefore(dayjs().startOf("day")) : false;
};
export const disablePreviousDatesUTC = (currentDate: Dayjs | null): boolean => {
  return !!currentDate && currentDate < dayjs.utc().startOf("day");
};

export const checkValidDateFormat = (date: string) => {
  if (date.includes(".")) {
    const pattern = /\./g;
    return date.replace(pattern, "-");
  } else {
    return date;
  }
};

export function wrapDatesWithDayjs(obj: any, keysToWrap?: string[]) {
  if (!obj) {
    return;
  }
  Object.keys(obj).forEach((key) => {
    const value = obj[key];

    if (value === null) {
      obj[key] = undefined;
    }

    if (value && typeof value === "object") {
      wrapDatesWithDayjs(value, keysToWrap);
    }

    if (
      typeof value === "string" &&
      (value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{1,3}Z$/) ||
        (keysToWrap && keysToWrap.includes(key)))
    ) {
      obj[key] = dayjs(value);
    }
  });

  return obj;
}

export function checkDateSend(sendDate: string, minutesBefore = 60) {
  const now = dayjs();
  const sendDateTime = dayjs(sendDate);

  const diffInMinutes = sendDateTime.diff(now, "minute");

  return diffInMinutes > minutesBefore;
}

export const disableCustomerActivityRange = (current: dayjs.Dayjs) => {
  const currentDate = dayjs();
  const currentWeekStart = currentDate.startOf("week").add(1, "day");

  const previousWeekEnd = currentDate
    .subtract(1, "week")
    .endOf("week")
    .add(1, "day");

  const nextWeekStart = currentWeekStart.add(1, "week");

  return (
    current &&
    ((current >= currentWeekStart && current < nextWeekStart) ||
      current >= nextWeekStart ||
      (current < currentWeekStart && current > previousWeekEnd))
  );
};

export const dateLocalUTCConverter = (
  date: string | undefined,
  type: string,
  format = LocalUTCFormat
) => {
  return date ? { [`${type}`]: dayjs(date).format(format) } : {};
};

export const readWithUTC = (date: string): Dayjs => {
  if (!date) {
    return dayjs();
  }
  return dayjs.utc(date);
};

export const dateFormat = (
  date?: dayjs.ConfigType,
  format = DayMonthYearHoursAndMinutes
) => {
  return dayjs(date).format(format);
};
