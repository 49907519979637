import { AuthBindings } from "@refinedev/core";
import axAPI from "../api/axios-instance";

export const authProvider: AuthBindings = {
  login: async ({ username, password }) => {
    try {
      const response = await axAPI({
        method: "POST",
        url: "/authorization/token",
        data: { username, password },
        headers: {
          "Content-Type": "application/json",
          accept: "*",
        },
      });

      if (response.status < 200 || response.status >= 300) {
        return {
          success: false,
          error: {
            message: "Login failed",
            name: "Invalid username",
          },
        };
      } else {
        localStorage.setItem("auth", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        return {
          success: true,
          redirectTo: "/",
        };
      }
    } catch (e: any) {
      if (e.response.status === 401) {
        return {
          success: false,
          error: {
            name: "Введите другие данные авторизации",
            message: "Пользователь не найден",
          },
        };
      }
      if (e.code === "ERR_NETWORK") {
        return {
          success: false,
          error: {
            name: "Ошибка на сервере",
            message: "Повторите попытку позже",
          },
        };
      }
      return Promise.reject();
    }
  },
  logout: async () => {
    localStorage.removeItem("auth");
    localStorage.removeItem("refresh_token");
    return {
      success: true,
      redirectTo: "/login",
    };
  },
  onError: async (error) => {
    if (error.statusCode === 401 || error.statusCode === 403) {
      try {
        const refreshResponse = await axAPI.post(
          "/authorization/token/refresh",
          {
            refreshToken: localStorage.getItem("refresh_token"),
          }
        );
        const { access_token, refresh_token } = refreshResponse.data;
        localStorage.setItem("auth", access_token);
        localStorage.setItem("refresh_token", refresh_token);
        location.reload();
        return {
          authenticated: true,
        };
      } catch (refreshError) {
        localStorage.removeItem("auth");
        localStorage.removeItem("refresh_token");
        return {
          authenticated: false,
          redirectTo: "/login",
          error: {
            name: "Авторизация",
            message: "Авторизуйтесь повторно",
          },
        };
      }
    } else {
      return { error };
    }
  },
  check: async () =>
    localStorage.getItem("auth")
      ? {
          authenticated: true,
        }
      : {
          authenticated: false,
          redirectTo: "/login",
        },
  getPermissions: async () => Promise.resolve(),
};
