import { TextField, useForm, useModal } from "@refinedev/antd";
import { FC } from "react";
import { errorNotification, successNotification } from "../../../../../helpers";
import { Button, Form, Input, Modal, Space } from "antd";
import s from "./Clubs-Change-Achievements.module.scss";
import { CloseOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { ClubsDeleteEntity } from "../Clubs-Delete-Entity/Clubs-Delete-Entity";
import { AppViewCard } from "../../../../../components";

type ClubsChangeAchievementsNewProps = {
  leagueId: number;
  teamId: number;
  achievements: string[];
  onRefetchCard: () => void;
};
export const ClubsChangeAchievements: FC<ClubsChangeAchievementsNewProps> = ({
  teamId,
  leagueId,
  achievements,
  onRefetchCard,
}) => {
  const { modalProps, show, close } = useModal();
  const { formProps, saveButtonProps, form, formLoading } = useForm<{
    url: string;
  }>({
    action: achievements ? "edit" : "create",
    resource: "achievements",
    meta: {
      teamId,
      leagueId,
    },
    dataProviderName: "clubsAchievementsDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: achievements ? "Достижение изменено" : "Достижение добавлено",
        type: "success",
      },
      callback: () => {
        close();
        onRefetchCard();
      },
    }),
    ...errorNotification({}),
  });

  const handleRefetchOnDelete = () => {
    onRefetchCard();
    form.setFieldValue("achievements", null);
  };

  const renderButtonText = !achievements?.length
    ? "Добавить достижения"
    : "Изменить список достижений";

  return (
    <>
      <Modal
        title={"Редактирование достижений"}
        onCancel={close}
        {...modalProps}
        footer={<></>}
      >
        <Form
          {...formProps}
          initialValues={{
            teamId,
            leagueId,
            achievements,
          }}
          className={s.addAchievementsForm}
          layout={"vertical"}
        >
          <Form.Item name={"teamId"} hidden />
          <Form.Item name={"leagueId"} hidden />
          <Form.List name="achievements">
            {(fields, { add, remove }) => (
              <>
                {fields?.map((field) => {
                  return (
                    <div
                      key={field.key}
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Item
                        name={field.name}
                        label={"Достижение:"}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "Необходимо указать достижение",
                          },
                          {
                            min: 5,
                            max: 100,
                            message:
                              "Достижение может быть от 5 до 100 символов",
                          },
                        ]}
                      >
                        <Input.TextArea
                          rows={2}
                          placeholder={"Укажите достижение"}
                        ></Input.TextArea>
                      </Form.Item>
                      <div
                        className={s.deleteAchievements}
                        onClick={(event) => {
                          remove(field.name);
                        }}
                      >
                        <CloseOutlined
                          style={{
                            color: "var(--danger)",
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
                <div className={s.addAchievements} onClick={() => add()}>
                  <PlusOutlined />
                  Добавить достижение
                </div>
              </>
            )}
          </Form.List>
          <Button className={s.button} type={"primary"} htmlType={"submit"}>
            {renderButtonText}
          </Button>
        </Form>
      </Modal>
      <AppViewCard label={"Достижения:"}>
        <Space
          direction={"horizontal"}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
          size={24}
        >
          <Space
            direction={"horizontal"}
            size={16}
            style={{
              width: "100%",
            }}
            wrap
          >
            {achievements?.length
              ? achievements?.map((value) => (
                  <div className={s.achievementsCard} key={value}>
                    <TextField value={value} />
                  </div>
                ))
              : "–"}
          </Space>
          <div className={s.wrapContainer}>
            <EditOutlined
              style={{
                fontSize: 20,
                cursor: "pointer",
                color: "var(--accent-main)",
              }}
              onClick={show}
            />
            <ClubsDeleteEntity
              onRefetch={handleRefetchOnDelete}
              deleteId={{ leagueId, teamId }}
              message={"Достижение успешно удалено"}
              okText={"Удалить достижение"}
              modalText={"Вы уверены, что хотите удалить достижение?"}
              dataProviderName={"clubsAchievementsDataProvider"}
            />
          </div>
        </Space>
      </AppViewCard>
    </>
  );
};
