import { AxiosInstance } from "axios";

import { DataProvider } from "@refinedev/core";
import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import axAPI from "../api/axios-instance";

import { blobToBase64 } from "../helpers/images.helper";
import { S3_LOCAL } from "../api/image-upload-api";

export const uploadDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    create: async ({ resource, variables, metaData }) => {
      const { entityId, serviceType, blob, type } = variables as {
        entityId: string;
        serviceType: string;
        type: string;
        blob: File;
      };

      const params = {
        Bucket: "fnl",
        Key: `${serviceType}/${entityId}`,
        Body: blob,
      };

      try {
        const formData = new FormData();
        formData.append("file", blob, blob.name);
        const result = await httpClient.post(
          "/cms/image/upload/type",
          formData,
          {
            params: {
              type,
            },
          }
        );
        return { s3id: result.data };
      } catch (error) {
        return Promise.reject(error);
      }
    },
  };
