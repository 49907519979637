import { FC, ReactNode } from "react";
import { CatchAllNavigate } from "@refinedev/react-router-v6";
import { Authenticated } from "@refinedev/core";

type ShowProviderProps = {
  children: ReactNode;
};
export const ShowProvider: FC<ShowProviderProps> = ({ children }) => {
  return (
    <Authenticated
      fallback={<CatchAllNavigate to="/login" />}
      v3LegacyAuthProviderCompatible
    >
      {children}
    </Authenticated>
  );
};
