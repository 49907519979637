import { ResourceProps } from "@refinedev/core";
import { UploadOutlined } from "@ant-design/icons";

export const usersImportResource: ResourceProps = {
  name: "usersimport",
  meta: {
    icon: <UploadOutlined />,
    label: "Импорт пользователей",
  },
  list: "/usersimport",
};
