import React, { FC, useContext, useMemo, useState } from "react";
import s from "./app-image-preview.module.scss";
import cn from "classnames";
import { EnvContext } from "../../providers";
import { s3Path } from "../../helpers";
import { NoImage } from "../icons/app-icons";
import { Modal } from "antd";
import { EyeOutlined } from "@ant-design/icons";

interface IAppImagePreviewProps {
  imageURL: string;
  useS3: boolean;
  sharedStyles?: string;
}

export const AppImagePreview: FC<IAppImagePreviewProps> = ({
  imageURL,
  sharedStyles,
  useS3 = false,
}) => {
  const { THUMBOR_URL } = useContext(EnvContext);
  const [error, setError] = useState(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const conditionSrc = useMemo(() => {
    if (useS3) {
      return s3Path({
        path: imageURL,
        original: true,
        thumbor: THUMBOR_URL,
      });
    } else {
      return imageURL;
    }
  }, [imageURL, useS3]);

  const handleShowPreview = () => {
    !error && setShowPreview(true);
  };
  const handleHidePreview = () => {
    !error && setShowPreview(false);
  };
  return (
    <>
      {imageURL ? (
        <div
          className={cn(s.imagePreviewContainer, sharedStyles)}
          onMouseEnter={handleShowPreview}
          onMouseLeave={handleHidePreview}
        >
          {error ? (
            <div className={s.imagePreviewContainerError}>
              <NoImage />
              Изображение отсутствует
            </div>
          ) : (
            <img
              className={s.imagePreviewContainerImage}
              src={conditionSrc}
              onError={(error) => (error ? setError(true) : setError(false))}
            />
          )}
          <div
            onClick={() => setOpenModal(true)}
            className={cn(s.imagePreviewContainerShow, {
              [s._show]: showPreview,
            })}
          >
            <EyeOutlined />
          </div>
        </div>
      ) : (
        <div className={cn(s.imagePreviewContainer, sharedStyles)}>
          <div className={s.imagePreviewContainerError}>
            <NoImage />
            Изображение отсутствует
          </div>
        </div>
      )}
      <Modal
        title="Просмотр изображения"
        open={openModal}
        width={600}
        onCancel={() => setOpenModal(false)}
        footer={<></>}
      >
        <div className={s.imagePreviewContainerPreviewContainer}>
          <img src={conditionSrc} />
        </div>
      </Modal>
    </>
  );
};
