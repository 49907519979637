import { Rule } from "antd/lib/form";

export const arrayLengthValidator = (
  length = 3,
  customError?: string
): Rule => {
  return {
    validator: async (_, value: any[]) => {
      if (!value) {
        return;
      }

      if (value?.length <= length) {
        return;
      }

      throw new Error(customError || `Значение не может быть больше ${length}`);
    },
  };
};
