import { Rule } from "antd/lib/form";
import { translationsTypesSelect } from "../pages/Matches/Lib";

export const streamValidator = (linkType: string): Rule => {
  return {
    validator: async (_, value: string) => {
      if (!linkType) {
        throw new Error("Нужно выбрать тип трансляции");
      }

      if (
        linkType === "youtube" &&
        (value.includes("youtu.be") || value.includes("youtube.com"))
      ) {
        return;
      }
      if (
        linkType === "tricolor" &&
        (value.includes("tricolor.ru") || value.includes("tricolor.tv"))
      ) {
        return;
      }
      if (linkType === "vk" && value.includes("vk.com")) {
        return;
      }
      if (linkType === "kinopoisk" && value.includes("kinopoisk.ru")) {
        return;
      }
      if (linkType === "match-tv" && value.includes("matchtv.ru")) {
        return;
      }
      if (linkType === "match-premier" && value.includes("matchpremier.ru")) {
        return;
      }

      const mapTitle = translationsTypesSelect.find(
        (select) => select.key === linkType
      )?.value;

      throw new Error(`Поле не соответствует типу трансляции – «${mapTitle}»`);
    },
  };
};
