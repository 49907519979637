import { Button, Form, Modal, Select } from "antd";
import { useForm, useModal } from "@refinedev/antd";
import { FC } from "react";
import { errorNotification, successNotification } from "../../../../helpers";
import { TagOutlined } from "@ant-design/icons";
import { PinInfoType } from "../../Lib/types";
import s from "../../News-List/All-News/All-News.module.scss";
import { nameLeagueMapByIndex } from "../../../Leagues/Lib/constants";

type PinnedDialogProps = {
  newsId: number;
  leaguesVariants: PinInfoType[];
  handleRefetchList: () => void;
};
export const UnPinnedDialog: FC<PinnedDialogProps> = ({
  newsId,
  leaguesVariants,
  handleRefetchList,
}) => {
  const { modalProps, show, close } = useModal();

  const { formProps, saveButtonProps, form, formLoading } = useForm<{
    url: string;
  }>({
    action: "edit",
    resource: "newsPin",
    id: newsId,
    dataProviderName: "newsPinDataProvider",
    redirect: false,
    queryOptions: {
      enabled: false,
    },
    ...successNotification({
      successData: {
        message: "Новость откреплена",
        type: "success",
      },
      callback: () => {
        close();
        form.resetFields();
        handleRefetchList();
      },
    }),
    ...errorNotification({}),
  });

  const leaguesTypesSelect = leaguesVariants.map((variant) => {
    return {
      key: variant.leagueId,
      value: nameLeagueMapByIndex[`${variant.leagueId}`],
    };
  });
  const onChangeLeague = (number: number) => {
    const findPinType = leaguesVariants.find(
      (league) => league.leagueId === number
    )?.pinType;

    form.setFieldValue("type", findPinType);
  };

  const afterClose = () => {
    form.resetFields();
  };

  return (
    <>
      <Button size={"small"} onClick={show} block icon={<TagOutlined />}>
        Открепить
      </Button>
      <Modal
        {...modalProps}
        afterClose={afterClose}
        onCancel={close}
        footer={<></>}
        title={"Открепление новости"}
      >
        <Form
          style={{
            width: "100%",
          }}
          {...formProps}
          layout={"vertical"}
        >
          <Form.Item
            name="leagueId"
            label={"Открепить в лиге"}
            className={s.input}
            rules={[
              {
                required: true,
                message: "Выберите вариант",
              },
            ]}
          >
            <Select
              showArrow={true}
              placeholder="Доступные лиги для открепления"
              onChange={onChangeLeague}
            >
              {leaguesTypesSelect.map((select) => {
                return (
                  <Select.Option key={select.key} value={select.key}>
                    {select.value}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item name={"type"} hidden></Form.Item>
          <Button htmlType={"submit"} type={"primary"}>
            Открепить новость
          </Button>
        </Form>
      </Modal>
    </>
  );
};
