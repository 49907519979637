import { Route } from "react-router-dom";
import { ShowProvider } from "../../../providers";

import { SupportMessagesList } from "../SupportMessagesList";
import { SupportMessage } from "../SupportMessage";

const supportMessagesRoutes = [
  <Route
    key={"support-messages"}
    path="support-messages"
    element={
      <ShowProvider>
        <SupportMessagesList />
      </ShowProvider>
    }
  />,
  <Route
    key={"support-message-show"}
    path="support-messages/:id"
    element={
      <ShowProvider>
        <SupportMessage />
      </ShowProvider>
    }
  />,
];

export const SUPPORT_MESSAGES_ROUTES = supportMessagesRoutes.map(
  (route) => route
);
