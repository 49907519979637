import { useNavigation } from "@refinedev/core";
import { useEffect } from "react";

export const RedirectPage = () => {
  const { push } = useNavigation();
  useEffect(() => {
    push("/matches/first-league");
  }, []);

  return null;
};
