import { List, TextField, useTable } from "@refinedev/antd";
import { CrudFilter } from "@refinedev/core";
import { Table } from "antd";
import { errorNotification } from "../../../helpers";
export const UsersList = () => {
  const { tableProps, searchFormProps } = useTable<any>({
    dataProviderName: "usersDataProvider",
    resource: "users",
    syncWithLocation: false,
    queryOptions: {
      enabled: true,
    },
    ...errorNotification(),
    onSearch: (params: any) => {
      const filters: CrudFilter[] = [];
      const { firstName, roleName } = params;

      filters.push({
        field: "firstName",
        operator: "null",
        value: firstName || undefined,
      });
      filters.push({
        field: "roleName",
        operator: "null",
        value: roleName || undefined,
      });

      return filters || [];
    },
  });

  return (
    <List
      title="Пользователи"
      resource={"users"}
      breadcrumb={false}
      canCreate={false}
      wrapperProps={{ style: { position: "sticky", top: 24 } }}
    >
      <Table
        {...tableProps}
        scroll={{ x: 1200 }}
        rowKey="id"
        locale={{
          emptyText: <div style={{ margin: 56 }}>Пользователи отсутствуют</div>,
        }}
        showSorterTooltip={false}
        pagination={{
          ...tableProps.pagination,
          pageSizeOptions: [10, 20, 50],
        }}
      >
        <Table.Column
          dataIndex={"bik"}
          key="bik"
          title={"ФИО"}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={"name"}
          key="name"
          title="Дата рождения"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={"name"}
          key="name"
          title="Любимый клуб"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={"bik"}
          key="bik"
          title={"Город"}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={"bik"}
          key="bik"
          title={"Email"}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex={"bik"}
          key="bik"
          title={"Телефон"}
          render={(value) => <TextField value={value} />}
        />
      </Table>
    </List>
  );
};
