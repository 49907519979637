import { Show, useTable } from "@refinedev/antd";
import { Space, Spin, Tag } from "antd";

import { HashTagsAdd } from "./Hash-Tags-Add";
import { HashTagsDelete } from "./Hash-Tags-Delete";
import { errorNotification } from "../../../helpers";

export const HashTagsList = () => {
  const { tableProps, searchFormProps, setFilters } = useTable<any>({
    dataProviderName: "hashTagsDataProvider",
    resource: "hashtags",
    syncWithLocation: false,
    queryOptions: {
      enabled: true,
    },
    ...errorNotification({}),
  });

  const hashTags = tableProps.dataSource;

  return (
    <Show
      contentProps={{ style: { height: 500 } }}
      title={"Хэштеги"}
      resource={"hashtags"}
      breadcrumb={false}
      headerButtons={<></>}
    >
      {!hashTags?.length && (
        <span
          style={{
            padding: 16,
            border: "1px solid var(--grey-300)",
            borderRadius: 6,
          }}
        >
          Хэштеги отсутствуют
        </span>
      )}
      {tableProps.loading ? (
        <Spin />
      ) : (
        <Space direction={"horizontal"} wrap size={4}>
          {hashTags?.map((tag) => {
            return (
              <Tag key={tag}>
                {tag} <HashTagsDelete tag={tag} />
              </Tag>
            );
          })}
          <HashTagsAdd />
        </Space>
      )}
    </Show>
  );
};
