import {
  CreateButton,
  List,
  ShowButton,
  TextField,
  useTable,
} from "@refinedev/antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Space, Table, Tooltip } from "antd";
import { errorNotification } from "../../../helpers";
import { AppImagePreview } from "../../../components";
import { PartnersType } from "../Lib";
import s from "../../News/News-List/All-News/All-News.module.scss";
import { useNavigation } from "@refinedev/core";
import { PartnersDelete } from "../Partners-Delete/Partners-Delete";
import { nameLeagueMapByIndex } from "../../Leagues/Lib/constants";
export const PartnersList = () => {
  const { push } = useNavigation();

  const { tableProps, searchFormProps } = useTable<PartnersType[]>({
    dataProviderName: "partnersDataProvider",
    resource: "partners",
    syncWithLocation: false,
    queryOptions: {
      enabled: true,
    },
    ...errorNotification(),
  });

  const handleNavigateToPartner = (id: string) => {
    push(`/partners/${id}`);
  };

  return (
    <List
      title={
        <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
          Партнеры{" "}
          <Tooltip title="Выбор лиги партнера влияет на отображение списка партнеров лиги в вебе (Лига)">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      }
      resource={"partners"}
      breadcrumb={false}
      canCreate={false}
      wrapperProps={{ style: { position: "sticky", top: 24 } }}
      headerButtons={
        <CreateButton resource={"partners"}>Добавить партнера</CreateButton>
      }
    >
      <Table
        {...tableProps}
        scroll={{ x: 1200 }}
        rowKey="id"
        locale={{
          emptyText: <div style={{ margin: 56 }}>Партнеры отсутствуют</div>,
        }}
        showSorterTooltip={false}
        pagination={false}
      >
        <Table.Column<PartnersType>
          dataIndex={""}
          width={300}
          key="bik"
          title={"Иконка"}
          render={(_, record) => (
            <AppImagePreview
              imageURL={record.photoUrl}
              useS3={true}
              sharedStyles={s.imageUrl}
            />
          )}
        />
        <Table.Column<PartnersType>
          width={200}
          dataIndex={"name"}
          key="name"
          title="Имя"
          render={(_, record) => <TextField value={record.name} />}
        />
        <Table.Column<PartnersType>
          width={200}
          dataIndex={"leagueId"}
          key="leagueId"
          title="Лига"
          render={(_, record) => (
            <TextField
              value={
                record.leagueId !== null
                  ? nameLeagueMapByIndex[`${record.leagueId}`]
                  : "Лига ФНЛ"
              }
            />
          )}
        />
        <Table.Column<PartnersType>
          width={200}
          dataIndex={"partnerType"}
          key="partnerType"
          title="Тип партнера"
          render={(_, record) => <TextField value={record.partnerType} />}
        />
        <Table.Column<PartnersType>
          width={200}
          dataIndex={"name"}
          key="name"
          title="Ссылка"
          render={(_, record) => <TextField value={record.link} />}
        />
        <Table.Column<PartnersType>
          width={260}
          dataIndex={"name"}
          fixed={"right"}
          key="name"
          title="Действия"
          render={(_, record) => (
            <Space size={8} direction="vertical" style={{ width: "100%" }}>
              <ShowButton
                onClick={() => handleNavigateToPartner(record.id)}
                size={"small"}
                block
              >
                Смотреть / Редактировать
              </ShowButton>
              <PartnersDelete id={Number(record.id)} name={record.name} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
