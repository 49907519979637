import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";

import { DataProvider } from "@refinedev/core";
import qs from "qs";
import axAPI from "../../../../api/axios-instance";
import { generateFilter } from "../../../../helpers";

export const mappingRusstatPlayersDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({ resource, sort, meta, filters }) => {
      const url = "cms/rs/player/mapping";
      const queryFilters = generateFilter(filters);
      try {
        const { data, headers } = await httpClient.get(
          `${url}?${qs.stringify({ ...queryFilters })}`
        );
        return {
          data,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    create: async ({
      resource,
      meta,
      variables,
    }: {
      meta: {
        fnlLeagueId: number;
        fnlPlayerId: number;
      };
      resource: string;
      variables: {
        rsPlayerId: number;
      };
    }) => {
      const url = "cms/rs/player/mapping";
      const { fnlLeagueId, fnlPlayerId } = meta;
      try {
        await httpClient.post(url, {
          fnlLeagueId,
          fnlPlayerId,
          ...variables,
        });

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    custom: async ({ meta, url }) => {
      const { playerName } = meta as {
        fnlLeagueId: number;
        fnlSeasonId: number;
        fnlTeamId: number;
        playerName: string;
      };

      const getUrl = `cms/rs/${url}`;

      const axiosResponse = await httpClient.get(
        `${getUrl}?${qs.stringify({
          playerName,
        })}`
      );

      const { data } = axiosResponse;

      return Promise.resolve({ data });
    },
  };
