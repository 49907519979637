import { Match } from "../../Lib/types";
import { FC } from "react";
import { Col, Row, Space, Typography } from "antd";
import { AppViewCard } from "../../../../components";
import { TextField } from "@refinedev/antd";

const { Title } = Typography;

type MatchesTeamsProps = Pick<Match, "guest" | "home">;

export const MatchesTeams: FC<MatchesTeamsProps> = ({ home, guest }) => {
  return (
    <Row gutter={8}>
      <Col span={12}>
        <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
          <Title level={5}>Домашняя команда</Title>
          <Row gutter={8}>
            <Col span={12}>
              <AppViewCard label={"Название команды:"}>
                <TextField value={home?.name} />
              </AppViewCard>
            </Col>
            <Col span={12}>
              <AppViewCard label={"Счет:"}>
                <TextField value={home?.score ?? "–"} />
              </AppViewCard>
            </Col>
          </Row>
        </Space>
      </Col>
      <Col span={12}>
        <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
          <Title level={5}>Гостевая команда</Title>
          <Row gutter={8}>
            <Col span={12}>
              <AppViewCard label={"Название команды:"}>
                <TextField value={guest?.name} />
              </AppViewCard>
            </Col>
            <Col span={12}>
              <AppViewCard label={"Счет:"}>
                <TextField value={guest?.score ?? "–"} />
              </AppViewCard>
            </Col>
          </Row>
        </Space>
      </Col>
    </Row>
  );
};
