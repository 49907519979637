import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import qs from "qs";

export const clubsAchievementsDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    update: async ({ resource, id, variables, meta }) => {
      const updateUrl = "/cms/team/achievements";

      try {
        const { data } = await httpClient.post(updateUrl, {
          ...variables,
          ...meta,
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    create: async ({ resource, variables, meta }) => {
      const updateUrl = "/cms/team/achievements";
      try {
        const { data } = await httpClient.post(updateUrl, {
          ...variables,
          ...meta,
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({ resource, id, meta }) => {
      const url = "/cms/team/achievements/delete";

      try {
        const { data } = await httpClient.post(
          `${url}?${qs.stringify({ ...meta })}`
        );
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
