import { Button, Form, Input, Modal, Radio, Space } from "antd";
import { useForm, useModal } from "@refinedev/antd";
import { errorNotification, successNotification } from "../../../../../helpers";
import s from "./Map-Players.module.scss";
import { useCustom } from "@refinedev/core";
import { useState } from "react";
import { useWatch } from "antd/es/form/Form";

type MapTeamProps = {
  fnlLeagueId: number;
  fnlPlayerId: number;
  refetchList: () => void;
  rsPlayers: {
    rsPlayerId: number;
    rsPlayerName: string;
    rsTeamTitle: string;
  }[];
};

type RusstatPlayersType = {
  rsPlayerId: number;
  rsPlayerName: string;
  rsTeamTitle: string;
};

export const MapPlayers = (clubs: MapTeamProps) => {
  const { modalProps, show, close } = useModal();
  const [rsPlayers, setRsPlayers] = useState<RusstatPlayersType[]>();

  const { formProps, saveButtonProps, form, formLoading, onFinish } = useForm<{
    url: string;
  }>({
    resource: "russtat-players",
    meta: {
      ...clubs,
    },
    dataProviderName: "mappingRusstatPlayersDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: "Маппинг прошел успешно",
        type: "success",
      },
      callback: () => {
        close();
        clubs.refetchList();
      },
    }),
    ...errorNotification({}),
  });
  const playerName = useWatch("playerName", form);

  const {
    data: rsTeamsData,
    refetch: refetchRsTeams,
    isFetching,
  } = useCustom({
    dataProviderName: "mappingRusstatPlayersDataProvider",
    url: "player",
    method: "get",
    queryOptions: {
      enabled: false,
    },
    meta: {
      playerName,
    },
  });

  const onSearch = () => {
    refetchRsTeams<RusstatPlayersType[]>().then(({ data }) => {
      const players = data?.data as RusstatPlayersType[];
      setRsPlayers(players || []);
    });
  };
  const onClearList = () => {
    setRsPlayers([]);
    form.resetFields(["rsPlayerId", "playerName"]);
  };

  const disableSearch = !playerName || playerName?.length <= 2;
  const emptyList = Boolean(!rsPlayers?.length || !rsPlayers);
  return (
    <>
      <Button size="small" onClick={show} block>
        Смапить игрока
      </Button>
      <Modal
        okText={"Подтвердить"}
        cancelText={"Отмена"}
        title={"Подтвердить действие"}
        onCancel={close}
        footer={null}
        {...modalProps}
      >
        <Form {...formProps} layout={"vertical"} className={s.form}>
          <Space
            style={{ width: "100%", display: "flex" }}
            direction={"horizontal"}
          >
            <Form.Item
              name={"playerName"}
              rules={
                emptyList
                  ? [
                      {
                        required: true,
                        message: "Введите имя",
                      },
                    ]
                  : []
              }
            >
              <Input placeholder={"Введите имя игрока"} />
            </Form.Item>
            <Form.Item>
              <Button
                loading={isFetching}
                htmlType={"button"}
                type={"primary"}
                disabled={disableSearch}
                onClick={onSearch}
              >
                Искать игрока
              </Button>
            </Form.Item>
            {Boolean(rsPlayers?.length) && (
              <Form.Item>
                <Button htmlType={"button"} onClick={onClearList}>
                  Очистить список
                </Button>
              </Form.Item>
            )}
          </Space>
          <Form.Item
            name={"rsPlayerId"}
            rules={[
              {
                required: true,
                message: "Необходимо выбрать игрока",
              },
            ]}
          >
            <Radio.Group className={s.playersList}>
              {rsPlayers?.map((select) => {
                return (
                  <Radio key={select.rsPlayerId} value={select.rsPlayerId}>
                    {select.rsPlayerName} ({select.rsTeamTitle})
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
          {emptyList && "Начните поиск, чтобы найти игрока"}
          <Space direction={"horizontal"} className={s.formFooter}>
            <Button type={"primary"} htmlType={"submit"}>
              Смапить игрока
            </Button>
            <Button onClick={close}>Отмена</Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
};
