import { MatchesList } from "../Matches-List/Matches-List";
import { Outlet, Route } from "react-router-dom";
import { ShowProvider } from "../../../providers";
import { MatchesShow } from "../Matches-Show/Matches-Show";
import { SeasonsList } from "../../Seasons";

const matchesRoutes = [
  <Route key={"matches"} path="matches" element={<Outlet />}>
    <Route key={"first-league"} path="first-league">
      <Route
        index
        element={
          <ShowProvider>
            <SeasonsList />
          </ShowProvider>
        }
      />
      <Route key={"first-league/season"} path={"season/:seasonId"}>
        <Route
          index
          element={
            <ShowProvider>
              <MatchesList />
            </ShowProvider>
          }
        />
        <Route
          path={"show/:matchId"}
          element={
            <ShowProvider>
              <MatchesShow />
            </ShowProvider>
          }
        />
      </Route>
    </Route>

    <Route key={"second-league"} path="second-league">
      <Route
        index
        element={
          <ShowProvider>
            <SeasonsList />
          </ShowProvider>
        }
      />
      <Route key={"second-league/season"} path={"season/:seasonId"}>
        <Route
          index
          element={
            <ShowProvider>
              <MatchesList />
            </ShowProvider>
          }
        />
        <Route
          path={"show/:matchId"}
          element={
            <ShowProvider>
              <MatchesShow />
            </ShowProvider>
          }
        />
      </Route>
    </Route>

    <Route key={"third-league"} path="third-league">
      <Route
        index
        element={
          <ShowProvider>
            <SeasonsList />
          </ShowProvider>
        }
      />
      <Route key={"third-league/season"} path={"season/:seasonId"}>
        <Route
          index
          element={
            <ShowProvider>
              <MatchesList />
            </ShowProvider>
          }
        />
        <Route
          path={"show/:matchId"}
          element={
            <ShowProvider>
              <MatchesShow />
            </ShowProvider>
          }
        />
      </Route>
    </Route>
  </Route>,
];

export const MATCHES_ROUTES = matchesRoutes.map((route) => route);
