import { ResourceProps } from "@refinedev/core";
import { UserOutlined } from "@ant-design/icons";

export const usersResource: ResourceProps = {
  name: "users",
  meta: {
    icon: <UserOutlined />,
    label: "Пользователи",
    hide: true,
  },
  list: "/users",
};
