import { FC, useState } from "react";
import { TextField, useForm } from "@refinedev/antd";
import { AppViewCard } from "../../../../../components";
import { errorNotification, successNotification } from "../../../../../helpers";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Select } from "antd";
import { colorNamesSelect, colorTypeSelect } from "../../../../Matches/Lib";
import s from "./Clubs-Change-Color.module.scss";
import { ClubsDeleteEntity } from "../Clubs-Delete-Entity/Clubs-Delete-Entity";
import cn from "classnames";

type ClubsChangeColorProps = {
  leagueId: number;
  teamId: number;
  color: {
    value: string;
    colorName: string;
    id: number | null;
  };
  onRefetchCard: () => void;
};

export const ClubsChangeColor: FC<ClubsChangeColorProps> = ({
  teamId,
  leagueId,
  color,
  onRefetchCard,
}) => {
  const [edit, setEdit] = useState(false);

  const { formProps, saveButtonProps, form, formLoading } = useForm<{
    url: string;
  }>({
    action: color ? "edit" : "create",
    resource: "clubs",
    meta: {
      teamId,
      leagueId,
    },
    dataProviderName: "clubsColorDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: color ? "Цвет изменен" : "Цвет добавлен",
        type: "success",
      },
      callback: () => {
        setEdit(false);
        onRefetchCard();
      },
    }),
    ...errorNotification({}),
  });

  const handleEdit = () => {
    setEdit(true);
  };

  const handleStopEdit = () => {
    setEdit(false);
    form.setFieldValue("color", color.value);
  };

  const handleRefetchOnDelete = () => {
    onRefetchCard();
    form.setFieldValue("color", null);
  };

  // const mapColors = useCallback(
  //   (fieldColor: string) => {
  //     if (!fieldColor) {
  //       return "–";
  //     }
  //
  //     const findColor = colorTypeSelect.find(
  //       ({ key, value }) => key === fieldColor
  //     );
  //
  //     return `${findColor?.value} (${fieldColor})`;
  //   },
  //   [color]
  // );

  if (!color.colorName && !color.value) {
    return (
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: 16,
          }}
        >
          <Form
            style={{
              width: "100%",
              display: "flex",
              gap: 8,
            }}
            {...formProps}
            initialValues={{
              teamId,
              leagueId,
            }}
            layout={"vertical"}
          >
            <Form.Item name={"teamId"} hidden />
            <Form.Item name={"leagueId"} hidden />
            <div
              style={{
                width: "100%",
                display: "flex",
                gap: 8,
              }}
            >
              <Form.Item
                name={"color"}
                style={{ flex: 1 }}
                rules={[
                  {
                    required: true,
                    message: "Необходимо указать цвет",
                  },
                ]}
              >
                <Select
                  placeholder="Выберите цвет"
                  notFoundContent={"Цвет не найден"}
                  allowClear
                >
                  {colorTypeSelect.map((color) => {
                    return (
                      <Select.Option key={color.key} value={color.key}>
                        <div
                          style={{
                            display: "flex",
                            gap: 8,
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <div
                            className={s.colorBlock}
                            style={{ backgroundColor: color.key }}
                          ></div>
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name={"colorName"}
                style={{ flex: 1 }}
                rules={[
                  {
                    required: true,
                    message: "Необходимо указать название цвета",
                  },
                ]}
              >
                <Select
                  placeholder="Выберите название цвета"
                  notFoundContent={"Цвет не найден"}
                  allowClear
                >
                  {colorNamesSelect.map((color) => {
                    return (
                      <Select.Option key={color} value={color}>
                        <TextField value={color} />
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </div>
            <Button className={s.button} type={"primary"} htmlType={"submit"}>
              Добавить цвет
            </Button>
          </Form>
        </div>
        <div style={{ color: "grey", fontSize: 12 }}>
          Название цвета отображается в Карточке клуба, изменение цвета
          персонализации применяется к Карточке матча (Состав и Статистика)
        </div>{" "}
      </>
    );
  }

  return (
    <>
      {!edit ? (
        <>
          <AppViewCard label={"Цвет формы:"}>
            <div className={s.colorViewContainer}>
              <div className={s.colorText}>
                <div
                  className={cn(s.colorBlock, s.view)}
                  style={{ backgroundColor: color.value }}
                ></div>
                <TextField style={{ width: "100%" }} value={color.colorName} />
              </div>
              <div className={s.colorText}>
                <EditOutlined
                  style={{
                    fontSize: 20,
                    cursor: "pointer",
                    color: "var(--accent-main)",
                  }}
                  onClick={handleEdit}
                />
                <ClubsDeleteEntity
                  onRefetch={handleRefetchOnDelete}
                  deleteId={{ leagueId, teamId }}
                  message={"Цвет успешно удален"}
                  okText={"Удалить цвет"}
                  modalText={"Вы уверены, что хотите удалить цвет?"}
                  dataProviderName={"clubsColorDataProvider"}
                />
              </div>
            </div>
          </AppViewCard>
          <div style={{ color: "grey", fontSize: 12 }}>
            Название цвета отображается в Карточке клуба, изменение цвета
            персонализации применяется к Карточке матча (Состав и Статистика)
          </div>{" "}
        </>
      ) : (
        <>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              gap: 16,
            }}
          >
            <Form
              style={{
                width: "100%",
                display: "flex",
                gap: 8,
              }}
              {...formProps}
              initialValues={{
                id: color.id,
                color: color.value,
                colorName: color.colorName,
              }}
              layout={"horizontal"}
            >
              <Form.Item name={"id"} hidden />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: 8,
                }}
              >
                <Form.Item
                  name={"color"}
                  style={{ flex: 1 }}
                  rules={[
                    {
                      required: true,
                      message: "Необходимо указать цвет",
                    },
                  ]}
                >
                  <Select
                    placeholder="Выберите цвет"
                    notFoundContent={"Цвет не найден"}
                    allowClear
                  >
                    {colorTypeSelect.map((color) => {
                      return (
                        <Select.Option key={color.key} value={color.key}>
                          <div
                            style={{
                              display: "flex",
                              gap: 8,
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <div
                              className={s.colorBlock}
                              style={{ backgroundColor: color.key }}
                            ></div>
                          </div>
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item
                  name={"colorName"}
                  style={{ flex: 1 }}
                  rules={[
                    {
                      required: true,
                      message: "Необходимо указать название цвета",
                    },
                  ]}
                >
                  <Select
                    placeholder="Выберите название цвета"
                    notFoundContent={"Цвет не найден"}
                    allowClear
                  >
                    {colorNamesSelect.map((color) => {
                      return (
                        <Select.Option key={color} value={color}>
                          <TextField value={color} />
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>

              <Button type={"primary"} htmlType={"submit"}>
                Изменить цвет
              </Button>
              <div className={s.iconContainer}>
                <CloseOutlined
                  style={{
                    fontSize: 20,
                    cursor: "pointer",
                    color: "var(--accent-main)",
                  }}
                  onClick={handleStopEdit}
                />
              </div>
            </Form>
          </div>
          <div style={{ color: "grey", fontSize: 12 }}>
            Название цвета отображается в Карточке клуба, изменение цвета
            персонализации применяется к Карточке матча (Состав и Статистика)
          </div>{" "}
        </>
      )}
    </>
  );
};
