import React from "react";

import App from "./App";
import "./index.scss";

import { AppPreloader } from "./components";
import { EnvProvider } from "./providers/env-provider";
import { createRoot } from "react-dom/client";

fetch("/config.json")
  .then((res) => res.json())
  .then((config) => {
    root.render(
      <EnvProvider config={config}>
        <React.Suspense
          fallback={
            <div className={"appSuspense"}>
              <AppPreloader />
            </div>
          }
        >
          <App />
        </React.Suspense>
      </EnvProvider>
    );
  });

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
