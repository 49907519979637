import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import qs from "qs";

export const translationsDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    update: async ({ resource, id, variables, meta }) => {
      const url = "/cms/match/updateBroadcast";

      try {
        const { data } = await httpClient.post(url, {
          id,
          ...variables,
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    create: async ({
      resource,
      variables,
      meta,
    }: {
      resource: string;
      variables: { typeLink: string };
      meta: {
        leagueId: number;
        matchId: number;
      };
    }) => {
      const url = "/cms/match/addBroadcast";

      const { leagueId, matchId } = meta;
      try {
        const { data } = await httpClient.post(url, {
          ...variables,
          leagueId,
          matchId,
          type: variables.typeLink === "vk" ? "BROADCAST" : "LINK",
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({ resource, id, meta }) => {
      const url = "/cms/match/removeBroadcast";

      const { broadcastId } = meta as {
        broadcastId: number;
      };

      try {
        const { data } = await httpClient.post(
          `${url}?${qs.stringify({ broadcastId })}`
        );
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
