import { Outlet, Route } from "react-router-dom";

import { ShowProvider } from "../../../providers";
import { MelbetList } from "../Melbet/List/Melbet-List";
import { RusstatList } from "../Russtat/List/Russtat-List";
import { ClubsList } from "../Russtat/Clubs-List";
import { PlayersList } from "../Russtat/Players-List";

const mappingRoutes = [
  <Route key={"mapping"} path="mapping" element={<Outlet />}>
    <Route
      key={"melbet"}
      path="melbet"
      element={
        <ShowProvider>
          <MelbetList />
        </ShowProvider>
      }
    />
    <Route
      key={"russtat"}
      path="russtat"
      element={
        <ShowProvider>
          <RusstatList />
        </ShowProvider>
      }
    />
    <Route
      key={"clubs-russtat"}
      path="russtat/clubs/:fnlLeagueId/:fnlSeasonId"
      element={
        <ShowProvider>
          <ClubsList />
        </ShowProvider>
      }
    />
    <Route
      key={"players-russtat"}
      path="russtat/clubs/:fnlLeagueId/:fnlSeasonId/:fnlTeamId"
      element={
        <ShowProvider>
          <PlayersList />
        </ShowProvider>
      }
    />
  </Route>,
];

export const MAPPING_ROUTES = mappingRoutes.map((route) => route);
