import { Route } from "react-router-dom";
import { ShowProvider } from "../../../providers";
import { NewsList } from "../News-List";
import { NewsCreate } from "../News-Create";
import { NewsShow } from "../News-Show";
import { NewsEdit } from "../News-Edit";

const newsRoutes = [
  <Route
    key={"news"}
    path="news"
    element={
      <ShowProvider>
        <NewsList />
      </ShowProvider>
    }
  />,
  <Route
    key={"news-create"}
    path="news/create"
    element={
      <ShowProvider>
        <NewsCreate />
      </ShowProvider>
    }
  />,
  <Route
    key={"news-show"}
    path="news/show/:id"
    element={
      <ShowProvider>
        <NewsShow />
      </ShowProvider>
    }
  />,
  <Route
    key={"news-edit"}
    path="news/edit/:id"
    element={
      <ShowProvider>
        <NewsEdit />
      </ShowProvider>
    }
  />,
];

export const NEWS_ROUTES = newsRoutes.map((route) => route);
