import { ResourceProps } from "@refinedev/core";
import { MessageOutlined } from "@ant-design/icons";

export const supportMessagesResource: ResourceProps = {
  name: "support-messages",
  meta: {
    icon: <MessageOutlined />,
    label: "Обращения",
  },
  list: "/support-messages",
  show: "/support-messages/:id",
};
