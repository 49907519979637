import { Route } from "react-router-dom";
import { ShowProvider } from "../../../providers";

import { PartnersList } from "../Partners-List/Partners-List";
import { PartnersShow } from "../Partners-Show/Partners-Show";

const partnersRoutes = [
  <Route
    key={"partners"}
    path="partners"
    element={
      <ShowProvider>
        <PartnersList />
      </ShowProvider>
    }
  />,
  <Route
    key={"partners-show"}
    path="partners/:id"
    element={
      <ShowProvider>
        <PartnersShow />
      </ShowProvider>
    }
  />,
  <Route
    key={"partners-create"}
    path="partners/create"
    element={
      <ShowProvider>
        <PartnersShow />
      </ShowProvider>
    }
  />,
];

export const PARTNERS_ROUTES = partnersRoutes.map((route) => route);
