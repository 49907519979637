import { ResourceProps } from "@refinedev/core";
import { TrophyOutlined } from "@ant-design/icons";

export const leaguesResource: ResourceProps = {
  name: "league-info",
  meta: {
    icon: <TrophyOutlined />,
    label: "О лиге",
  },
  list: "/league",
};
