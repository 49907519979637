import { Rule } from "antd/lib/form";

export const validateNewsTitle: Rule = {
  validator: async (_, value: string) => {
    if (value && !/^[a-zA-Zа-яА-Я0-9\s"'`´«»“”\-[\]{}|:.(),!]*$/.test(value)) {
      throw new Error(
        "Поле может содержать только буквы, цифры, пробелы и разрешенные специальные символы: кавычки (все виды), тире, вертикальный слеш, двоеточие, точки, скобки (круглые, квадратные, фигурные), запятые, восклицательный знак."
      );
    }
  },
};
