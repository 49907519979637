import { List, ShowButton, TextField, useTable } from "@refinedev/antd";
import { CrudFilter, useNavigation } from "@refinedev/core";
import { Button, Form, Space, Table, TablePaginationConfig } from "antd";
import { useMatch } from "react-router-dom";
import { leagueIndex } from "../Lib";
import { dateFormat, DayMonthYear, errorNotification } from "../../../helpers";
import type { MatchesGrid, Match } from "../Lib/types";
import s from "./Matches-List.module.scss";
import { TourFilter } from "./Filters/Tour-Filter";
import { AppTitle } from "../../../components";
import { useEffect, useMemo, useState } from "react";
import { GroupFilter } from "./Filters/Group-Filter";

type TablePaginationProps = TablePaginationConfig & { total: number };
export const MatchesList = () => {
  const route = useMatch("/:parent/:league/:season/:seasonId");
  const resource = route?.params.league;
  const seasonId = route?.params.seasonId;
  const { push } = useNavigation();
  const [clear, setClear] = useState<boolean>(false);
  const [groupId, setGroupId] = useState();

  const { tableProps, searchFormProps, setFilters, tableQueryResult } =
    useTable<MatchesGrid>({
      dataProviderName: "matchesDataProvider",
      resource: "matches",
      meta: {
        leagueId: leagueIndex[`${resource}`],
        seasonId,
        clear,
      },
      syncWithLocation: false,
      queryOptions: {
        enabled: true,
      },
      ...errorNotification({
        errorData: {
          errorKeys: [
            {
              errorCode: "channelPoints.invalid",
              message:
                "Невозможно добавить баллы без создания маркетингового канала",
            },
          ],
        },
      }),
      onSearch: (params: any) => {
        const filters: CrudFilter[] = [];
        const { tourId, groupId } = params;

        filters.push({
          field: "tourId",
          operator: "null",
          value: tourId || undefined,
        });

        filters.push({
          field: "groupId",
          operator: "null",
          value: groupId || undefined,
        });

        return filters || [];
      },
    });

  const tableLimit = useMemo(() => {
    const total = (tableProps.pagination as TablePaginationProps).total;
    return total >= 10;
  }, [tableProps.pagination]);

  const handleNavigateToMatches = (matchId: number) => {
    push(`show/${matchId}`);
  };
  const watchGroup = Form.useWatch("groupId", searchFormProps.form);
  const watchTour = Form.useWatch("tourId", searchFormProps.form);

  const handleRefetch = () => {
    setClear(true);
    searchFormProps.form?.resetFields();
    setFilters([]);
    setTimeout(() => {
      setClear(false);
    }, 100);
  };
  useEffect(() => {
    searchFormProps.form?.resetFields(["tourId"]);
  }, [groupId]);

  return (
    <List
      title={<AppTitle canGoBack>Матчи</AppTitle>}
      resource={"matches"}
      breadcrumb={false}
      canCreate={false}
      wrapperProps={{ style: { position: "sticky", top: 24 } }}
    >
      <Space size={8} direction="vertical" style={{ width: "100%" }}>
        <Form
          {...searchFormProps}
          layout="horizontal"
          className={s.modalContainerSearch}
        >
          {leagueIndex[`${resource}`] !== "100" && (
            <>
              <GroupFilter
                seasonId={Number(seasonId)}
                leagueId={Number(leagueIndex[`${resource}`])}
                onChangeGroup={setGroupId}
              />
            </>
          )}
          <TourFilter
            seasonId={Number(seasonId)}
            leagueId={Number(leagueIndex[`${resource}`])}
            groupId={groupId}
          />
          <Button disabled={!watchTour && !watchGroup} onClick={handleRefetch}>
            Очистить фильтры
          </Button>

          <Button
            onClick={() => {
              searchFormProps.form?.submit();
            }}
            htmlType="submit"
            type="primary"
          >
            Применить
          </Button>
        </Form>
        <Table
          {...tableProps}
          scroll={{ x: 1200 }}
          rowKey="id"
          locale={{
            emptyText: <div style={{ margin: 56 }}>Матчи отсутствуют</div>,
          }}
          showSorterTooltip={false}
          pagination={
            tableLimit
              ? {
                  ...tableProps.pagination,
                  pageSizeOptions: [10, 20, 50],
                }
              : false
          }
        >
          <Table.Column<Match>
            dataIndex={"tour"}
            key="tour"
            title="Тур"
            render={(value) => <TextField value={value} />}
          />
          {leagueIndex[`${resource}`] !== "100" && (
            <Table.Column<Match>
              dataIndex={"group"}
              key="group"
              title="Группа"
              render={(value) => <TextField value={value} />}
            />
          )}
          <Table.Column<Match>
            dataIndex={"date"}
            key="date"
            title="Дата"
            render={(value) => (
              <TextField value={dateFormat(value, DayMonthYear)} />
            )}
          />
          <Table.Column<Match>
            key="name"
            title="Команды"
            render={(value) => {
              return (
                <Space direction={"vertical"}>
                  <Space>
                    <TextField value={"Домашняя команда:"} />
                    <TextField value={value?.home.name ?? "–"} />
                  </Space>
                  <Space>
                    <TextField value={"Гостевая команда:"} />
                    <TextField value={value?.guest.name ?? "–"} />
                  </Space>
                </Space>
              );
            }}
          />
          <Table.Column<Match>
            key="stadium"
            title="Стадион"
            render={(value) => {
              return (
                <Space direction={"vertical"}>
                  <Space>
                    <TextField value={"Название:"} />
                    <TextField value={value?.stadium.title ?? "–"} />
                  </Space>
                  <Space>
                    <TextField value={"Город:"} />
                    <TextField value={value?.stadium.city ?? "–"} />
                  </Space>
                </Space>
              );
            }}
          />
          <Table.Column<Match>
            width={"15%"}
            title={"Действия"}
            align={"center"}
            dataIndex="actions"
            render={(_, record) => (
              <ShowButton
                size="small"
                onClick={() => handleNavigateToMatches(record.matchId)}
              >
                Посмотреть
              </ShowButton>
            )}
          />
        </Table>
      </Space>
    </List>
  );
};
