import { Rule } from "antd/lib/form";

export const tagsValidator: Rule = {
  validator: async (_, value: string) => {
    if (!value || /^[a-zA-Zа-яА-Я0-9_ёЁ—–\- ]+$/.test(value)) {
      return;
    }
    throw new Error("Поле не должно содержать специальных символов");
  },
};
