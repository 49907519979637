import { useMatch } from "react-router-dom";
import { useOne } from "@refinedev/core";
import { Show, useTable } from "@refinedev/antd";
import { Space, Typography, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { errorNotification } from "../../../helpers";
import { AppTitle } from "../../../components";
import { AllClubInfo } from "../Lib";
import { leagueIndex, nameLeagueMap } from "../../Matches/Lib";
import {
  ClubsMainInfo,
  ClubsTrainersInfo,
  ClubsAdditionalInfo,
  ClubsSocialLinks,
  ClubsMedia,
  ClubsSocialLinksAdd,
} from "./components";

import { ClubsTags } from "./components/Clubs-Trainers-Info/Clubs-Tags";

const { Title } = Typography;

export const ClubsShow = () => {
  const route = useMatch("/:parent/:league/:season/:seasonId/:show/:clubId");

  const teamId = route?.params.clubId;
  const leagueId = leagueIndex[`${route?.params.league}`];
  const seasonId = route?.params.seasonId;

  const leagueName = nameLeagueMap[`${route?.params.league}`];

  const { data, isLoading, refetch } = useOne<AllClubInfo>({
    resource: "clubs",
    id: "",
    meta: {
      teamId,
      leagueId,
      seasonId,
    },
    queryOptions: {
      enabled: !!(teamId && leagueId),
    },
    dataProviderName: "clubsDataProvider",
    ...errorNotification(),
  });

  const { tableProps, searchFormProps, setFilters } = useTable<any>({
    dataProviderName: "hashTagsDataProvider",
    resource: "hashtags",
    syncWithLocation: false,
    queryOptions: {
      enabled: true,
    },
  });

  const handleRefetchCard = () => {
    refetch();
  };

  const record = data?.data as AllClubInfo;

  const hashTags = (tableProps.dataSource || []) as string[];

  return (
    <Show
      isLoading={isLoading}
      headerButtons={<></>}
      title={
        <AppTitle canGoBack isLoading={isLoading}>
          {`Команда – «${record?.club.title}»`}
        </AppTitle>
      }
    >
      <Space direction={"vertical"} size={32} style={{ width: "100%" }}>
        <ClubsMainInfo {...record?.club} onRefetch={handleRefetchCard}>
          <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
            <Title level={5}>Социальные сети</Title>
            <Tooltip title="Можно добавить максимум три ссылки, по одной каждого типа">
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <ClubsSocialLinks
            {...record?.club}
            onRefetchCard={handleRefetchCard}
          />
          <ClubsSocialLinksAdd
            teamId={Number(teamId)}
            leagueId={Number(leagueId)}
            onRefetchCard={handleRefetchCard}
          />
        </ClubsMainInfo>
        <ClubsAdditionalInfo
          info={record?.club}
          leagueName={leagueName}
          onRefetch={handleRefetchCard}
        />
        <ClubsTags
          teamId={teamId}
          leagueId={leagueId}
          initialHashTags={hashTags}
        />
        <ClubsTrainersInfo trainers={record?.trainers} />
        <ClubsMedia
          hashTags={hashTags}
          teamId={record?.club.teamId}
          media={record?.media}
          onRefetchCard={handleRefetchCard}
          leagueId={record?.club.leagueId}
        />
      </Space>
    </Show>
  );
};
