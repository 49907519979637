import { RusstatPlayersRowType } from "../Players-List";
import { FC } from "react";
import { Button, Modal, Space, Tag, Typography } from "antd";
import { useModal } from "@refinedev/antd";
import { useCreate } from "@refinedev/core";
import { successNotification } from "../../../../../helpers";

const { Text } = Typography;

type MapMultiplePlayersProps = {
  players: RusstatPlayersRowType[];
  refetchList: () => void;
  isTable?: boolean;
};
export const MapAutoPlayers: FC<MapMultiplePlayersProps> = ({
  players,
  refetchList,
  isTable = false,
}) => {
  const { modalProps, show, close } = useModal();
  const { mutate, isLoading } = useCreate();

  const handleMap = () => {
    const values = players.map(({ rsPlayerId, fnlLeagueId, fnlPlayerId }) => {
      return { rsPlayerId, fnlLeagueId, fnlPlayerId };
    });

    mutate({
      resource: "russtat-players",
      dataProviderName: "mappingAutoRusstatPlayersDataProvider",
      values,
      ...successNotification({
        successData: {
          message: "Маппинг прошел успешно",
          type: "success",
        },
        callback: () => {
          close();
          refetchList();
        },
      }),
    });
  };

  return (
    <>
      <Button
        size={isTable ? "small" : "middle"}
        type={isTable ? "default" : "primary"}
        disabled={!players.length}
        onClick={show}
        block
      >
        Автомапинг
      </Button>
      <Modal
        {...modalProps}
        title={"Автомапинг"}
        footer={
          <Space style={{ display: "flex", marginTop: 24 }}>
            <Button loading={isLoading} onClick={handleMap} type={"primary"}>
              Подтвердить
            </Button>
          </Space>
        }
      >
        <Space direction={"vertical"}>
          <Text>Вы уверены, что хотите провести Автомапинг?</Text>
          <Text>{isTable ? "Игрок" : "Игроки:"}</Text>
          <Space direction={"horizontal"} wrap>
            {players.map((player) => (
              <Tag key={player.fnlPlayerId}>{player.fnlPlayerName}</Tag>
            ))}
          </Space>
        </Space>
      </Modal>
    </>
  );
};
