import { FC } from "react";
import { useModal } from "@refinedev/antd";
import { useDelete, useNavigation } from "@refinedev/core";
import { errorNotification, successNotification } from "../../../helpers";
import { DeleteOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";

type NewsDeleteProps = {
  id: number;
  title: string;
  type?: "page" | "table";
};

export const MessageDelete: FC<NewsDeleteProps> = ({
  id,
  title,
  type = "table",
}) => {
  const { modalProps, show, close } = useModal();
  const { mutate: deleteMutate } = useDelete();
  const { push } = useNavigation();

  const handleDelete = () => {
    deleteMutate({
      dataProviderName: "supportMessagesDataProvider",
      resource: "support-messages",
      id: "",
      meta: {
        id,
      },
      ...successNotification({
        successData: {
          message: `Обращение «${title}» успешно удалено`,
          type: "success",
        },
        callback: () => {
          close();
          push("/support-messages");
        },
      }),
      ...errorNotification({}),
    });
  };

  return (
    <>
      {type === "table" ? (
        <Button
          size={"small"}
          danger
          onClick={show}
          block
          icon={<DeleteOutlined />}
        >
          Удалить
        </Button>
      ) : (
        <Button danger onClick={show} block icon={<DeleteOutlined />}>
          Удалить Обращение
        </Button>
      )}

      <Modal
        okText={"Удалить"}
        cancelText={"Отмена"}
        onOk={handleDelete}
        title={"Подтверждение"}
        onCancel={close}
        {...modalProps}
      >
        Вы уверены что хотите удалить обращение «{title}»?
      </Modal>
    </>
  );
};
