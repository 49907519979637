import { List, ShowButton, TextField, useTable } from "@refinedev/antd";
import { errorNotification } from "../../helpers";
import { useEffect, useState } from "react";
import { Table } from "antd";
import { v4 as randomId } from "uuid";
import { useMatch } from "react-router-dom";
import { leagueIndex, nameLeagueTitle } from "../Matches/Lib";
import { useNavigation } from "@refinedev/core";

export const SeasonsList = () => {
  const match = useMatch("/:parent/:league/*");
  const { push } = useNavigation();
  const resource = match?.params.league;
  const baseRoute = match?.params.parent as "matches" | "clubs";

  const [datasource, setDatasource] = useState<Array<any>>([]);

  const { tableQueryResult } = useTable<any>({
    dataProviderName: "seasonsDataProvider",
    resource: "seasons",
    syncWithLocation: false,
    meta: {
      leagueId: leagueIndex[`${resource}`],
    },
    ...errorNotification({
      errorData: {
        errorKeys: [
          {
            errorCode: "typeMismatch",
            message: "Неверное значение при чтении id пользователя",
          },
        ],
      },
    }),
  });

  const fetchData = async () => {
    const { data } = await tableQueryResult.refetch();
    if (data?.data) {
      await setDatasource(data.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, [resource]);

  const handleNavigateToRoute = (seasonId: number) => {
    push(`season/${seasonId}`);
  };

  return (
    <List
      headerButtons={<></>}
      breadcrumb={false}
      title={nameLeagueTitle[`${resource}`]}
    >
      <Table
        dataSource={datasource}
        pagination={false}
        loading={tableQueryResult.isFetching}
        rowKey={() => randomId()}
        locale={{
          emptyText: <div style={{ margin: 56 }}>Сезоны не найдены</div>,
        }}
        showSorterTooltip={false}
      >
        <Table.Column<any>
          width={30}
          title="Название сезона"
          render={(_, record) => <TextField value={record.title || "–"} />}
        />
        <Table.Column<any>
          width={"15%"}
          title={"Действия"}
          align={"center"}
          dataIndex="actions"
          render={(_, record) => (
            <ShowButton
              size="small"
              onClick={() => handleNavigateToRoute(record.seasonId)}
            >
              Смотреть {baseRoute === "matches" ? "матчи" : "клубы"}
            </ShowButton>
          )}
        />
      </Table>
    </List>
  );
};
