import s from "./app-preloader.module.scss";

export const AppPreloader = () => {
  return (
    <div className={s.rootLoader}>
      <div className={s.rootLoader__outer}>
        <div className={s.preload__inner}></div>
      </div>
    </div>
  );
};
