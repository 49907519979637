import React from "react";

import s from "./login.module.scss";

import cn from "classnames";
import { useLogin } from "@refinedev/core";
import { Button, Card, Col, Form, Input, Layout, Row, Typography } from "antd";

const { Text, Title } = Typography;

export interface ILoginForm {
  username: string;
  password: string;
  remember: boolean;
}

export const Login: React.FC = () => {
  const [form] = Form.useForm<ILoginForm>();

  const { mutate: login, isLoading } = useLogin<ILoginForm>();

  const CardTitle = (
    <Title level={5} className={s.title}>
      Авторизация
    </Title>
  );

  return (
    <Layout className={s.layout}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          <div className={s.container}>
            <div className={s.imageContainer}>
              <Title className={cn(s.title, s.mainTitle)} level={3}>
                FNL CMS
              </Title>
            </div>
            <Card title={CardTitle} headStyle={{ borderBottom: 0 }}>
              <Form<ILoginForm>
                layout="vertical"
                form={form}
                onFinish={(values) => {
                  login(values);
                }}
                requiredMark={false}
                initialValues={{
                  remember: false,
                }}
              >
                <Form.Item
                  name="username"
                  label="Логин"
                  rules={[{ required: true, message: "Укажите логин" }]}
                >
                  <Input size="large" placeholder="Имя пользователя" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Пароль"
                  rules={[{ required: true, message: "Укажите пароль" }]}
                  style={{ marginBottom: "12px" }}
                >
                  <Input type="password" placeholder="●●●●●●●●" size="large" />
                </Form.Item>
                {/*<div style={{ marginBottom: "12px" }}>*/}
                {/*    <Form.Item*/}
                {/*        name="remember"*/}
                {/*        valuePropName="checked"*/}
                {/*        noStyle*/}
                {/*    >*/}
                {/*        <Checkbox*/}
                {/*            style={{*/}
                {/*                fontSize: "12px",*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            Remember me*/}
                {/*        </Checkbox>*/}
                {/*    </Form.Item>*/}

                {/*    <a*/}
                {/*        style={{*/}
                {/*            float: "right",*/}
                {/*            fontSize: "12px",*/}
                {/*        }}*/}
                {/*        href="#"*/}
                {/*    >*/}
                {/*        Забыли пароль?*/}
                {/*    </a>*/}
                {/*</div>*/}
                <Button
                  loading={isLoading}
                  type="primary"
                  size="large"
                  htmlType="submit"
                  block
                >
                  Авторизоваться
                </Button>
              </Form>
              {/*<div style={{ marginTop: 8 }}>*/}
              {/*    <Text style={{ fontSize: 12 }}>*/}
              {/*        Don’t have an account?{" "}*/}
              {/*        <a href="#" style={{ fontWeight: "bold" }}>*/}
              {/*            Аторизоваться*/}
              {/*        </a>*/}
              {/*    </Text>*/}
              {/*</div>*/}
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );
};
