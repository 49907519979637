import { Create, useForm } from "@refinedev/antd";
import {
  dateFormat,
  DayMonthYearHoursAndMinutes,
  disablePreviousDates,
  errorNotification,
  hoursAndMinutes,
  successNotification,
} from "../../../helpers";

import locale from "antd/es/date-picker/locale/ru_RU";

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Tooltip,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { AppImageUploader, AppTitle, EFileTypes } from "../../../components";
import { v4 as randomId } from "uuid";
import { NewsBlockCreate } from "./News-Block-Create";
import { NewCreateHashTags } from "./New-Create-Hash-Tags";
import { useWatch } from "antd/es/form/Form";
import { useMemo } from "react";
import dayjs from "dayjs";

export const NewsCreate = () => {
  const { formProps, saveButtonProps, form, formLoading, onFinish } =
    useForm<any>({
      resource: "news",
      dataProviderName: "newsDataProvider",
      redirect: "list",
      ...successNotification({
        successData: {
          message: "Размещение успешно создано",
          type: "success",
        },
      }),
      ...errorNotification({}),
    });

  const watchReleaseDate = useWatch("newsDate", form);
  const isPublic = useWatch("isPublic", form);

  const renderDate = useMemo(() => {
    if (watchReleaseDate === "Invalid Date") {
      return dateFormat();
    }
    return dateFormat(watchReleaseDate, DayMonthYearHoursAndMinutes);
  }, [watchReleaseDate]);

  const onSubmitForm = (values: any) => {
    onFinish({ ...values, isPublic });
  };

  return (
    <Create
      title={
        <AppTitle canGoBack routerLink={"/news"}>
          Создать новость
        </AppTitle>
      }
      breadcrumb={false}
      headerButtons={null}
      footerButtons={<></>}
      goBack={false}
    >
      <Form
        {...formProps}
        onFinish={onSubmitForm}
        initialValues={{
          newsDate: dayjs(),
          isPublic: false,
          ...formProps.initialValues,
        }}
        layout={"vertical"}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Space size={16} direction="vertical" style={{ width: "100%" }}>
              <Form.Item
                name={"mainPhotoUrl"}
                label={
                  <>
                    Изображение новости&nbsp;
                    <Tooltip title="Максимальный размер фото 5Мб">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </>
                }
                rules={
                  isPublic && [
                    {
                      required: true,
                      message: "Укажите изображение",
                    },
                  ]
                }
              >
                <AppImageUploader
                  type="NEWS"
                  entityId={`${randomId()}`}
                  serviceType={EFileTypes.NEWS}
                  cropperProps={{
                    modalTitle: "Редактировать изображение",
                  }}
                  uploadProps={{
                    multiple: false,
                    children: "Изображение новости",
                    listType: "picture-card",
                  }}
                />
              </Form.Item>

              <Form.Item
                label={
                  <>
                    Заголовок&nbsp;
                    <Tooltip title="Заголовок может быть от 5 до 100 символов">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </>
                }
                rules={[
                  { required: true, message: "Укажите заголовок" },
                  {
                    min: 5,
                    max: 120,
                    message: "Заголовок может быть от 5 до 120 символов",
                  },
                  // validateNewsTitle,
                ]}
                name={"title"}
              >
                <Input.TextArea rows={2} placeholder={"Укажите заголовок"} />
              </Form.Item>
              <Form.Item
                label={
                  <>
                    Превью новости&nbsp;
                    <Tooltip title="Описание может быть от 5 до 100 символов">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </>
                }
                rules={[
                  // { required: true, message: "Укажите превью" },
                  {
                    min: 5,
                    max: 200,
                    message: "Описание может быть от 5 до 200 символов",
                  },
                ]}
                name={"preview"}
              >
                <Input.TextArea rows={2} placeholder={"Укажите заголовок"} />
              </Form.Item>
              {/*isPublic*/}
              <NewsBlockCreate form={form} isPublic={true} />
              <Form.Item
                name="leagueId"
                label={
                  <div
                    style={{ display: "flex", gap: 4, alignItems: "center" }}
                  >
                    Лига{" "}
                    <Tooltip
                      title={`Если в поле "Лига" указать "Все" новость будет опубликована на все лиги и придет уведомление о новости лиги`}
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
                rules={
                  //isPublic
                  [{ required: true, message: "Укажите лигу" }]
                }
              >
                <Select showArrow={true} placeholder="Выберите лигу">
                  <Select.Option key={"100"} value={100}>
                    Первая лига
                  </Select.Option>
                  <Select.Option key={"200"} value={200}>
                    Вторая лига А
                  </Select.Option>
                  <Select.Option key={"300"} value={300}>
                    Вторая лига Б
                  </Select.Option>
                  <Select.Option key={"0"} value={0}>
                    Все
                  </Select.Option>
                </Select>
              </Form.Item>
              <NewCreateHashTags />
              <Form.Item
                name={"newsDate"}
                label={
                  <div
                    style={{ display: "flex", gap: 4, alignItems: "center" }}
                  >
                    Дата публикации{" "}
                    <Tooltip
                      title={`Если дата публикации указывается в будущем времени, то новость не отобразится до указанной даты`}
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
                // rules={[{ message: "Укажите дату публикацию" }]}
              >
                <DatePicker
                  locale={locale}
                  allowClear={false}
                  format={DayMonthYearHoursAndMinutes}
                  showTime={{ format: hoursAndMinutes }}
                  style={{ width: "100%" }}
                  placeholder={"Укажите дату"}
                />
              </Form.Item>

              <Space
                direction={"horizontal"}
                align={"baseline"}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Form.Item
                  name={"showDate"}
                  valuePropName="checked"
                  initialValue={true}
                >
                  <Checkbox>Отображать дату публикации</Checkbox>
                </Form.Item>
                <div>Дата публикации: {renderDate}</div>
              </Space>
              <Form.Item
                name={"isPublic"}
                label={
                  <div
                    style={{ display: "flex", gap: 4, alignItems: "center" }}
                  >
                    Отредактировать как:{" "}
                    <Tooltip
                      title={`При сохранении новости как Черновик, она не будет опубликована`}
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </div>
                }
              >
                <Radio.Group>
                  <Radio value={false}>Черновик</Radio>
                  <Radio value={true}>Публикацию</Radio>
                </Radio.Group>
              </Form.Item>
              <Space>
                <Button type={"primary"} htmlType={"submit"}>
                  Создать новость
                </Button>
              </Space>
            </Space>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
