import { useTable } from "@refinedev/antd";
import { Space, Spin, Tag, Typography, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FC } from "react";
import { errorNotification } from "../../../../../helpers";
import { ClubsTagsAdd } from "./Clubs-Tags-Add";

const { Title } = Typography;

type ClubsTagsProps = {
  teamId?: string;
  leagueId?: string;
  initialHashTags: string[];
};
export const ClubsTags: FC<ClubsTagsProps> = ({
  leagueId,
  teamId,
  initialHashTags,
}) => {
  const { tableProps, searchFormProps, setFilters } = useTable({
    dataProviderName: "clubsTagsDataProvider",
    resource: "hashtags",
    syncWithLocation: false,
    queryOptions: {
      enabled: true,
    },
    ...errorNotification({
      errorData: {
        errorKeys: [
          { errorCode: "500", message: "Не удалось получить хэштеги команды" },
        ],
      },
    }),
    filters: {
      initial: [
        { operator: "null", value: leagueId, field: "leagueId" },
        { operator: "null", value: teamId, field: "teamId" },
      ],
    },
  });

  const hashTags = (tableProps.dataSource || []) as unknown as string[];

  return (
    <Space direction={"vertical"} size={32} style={{ width: "100%" }}>
      <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
        <Title level={5}>Хэштеги команды</Title>
        <Tooltip title="Хэштеги команды используются для уведомлений по новостям. Если в хэштеги новости добавлен хэштег команды, то пользователю придет уведомление о новости по команде">
          <InfoCircleOutlined />
        </Tooltip>
      </div>
      <div>
        {!hashTags?.length && (
          <span
            style={{
              padding: 16,
              border: "1px solid var(--grey-300)",
              borderRadius: 6,
            }}
          >
            Хэштеги отсутствуют
          </span>
        )}
        {tableProps.loading ? (
          <Spin />
        ) : (
          <Space direction={"horizontal"} wrap size={4}>
            {hashTags?.map((tag) => {
              return <Tag key={tag}>{tag}</Tag>;
            })}
            <ClubsTagsAdd
              initialHashTags={initialHashTags}
              teamId={teamId}
              leagueId={leagueId}
              hashTags={hashTags || []}
            />
          </Space>
        )}
      </div>
    </Space>
  );
};
