import { Rule } from "antd/lib/form";

export const socialLinksValidator = (linkType: string): Rule => {
  return {
    validator: async (_, value: string) => {
      if (!linkType) {
        throw new Error("Нужно выбрать тип трансляции");
      }

      if (
        linkType === "YOUTUBE" &&
        (value.includes("youtu.be") || value.includes("youtube.com"))
      ) {
        return;
      }
      if (linkType === "VK" && value.includes("vk.com")) {
        return;
      }
      if (linkType === "TELEGRAM" && value.includes("t.me/")) {
        return;
      }

      throw new Error(`Поле не соответствует типу ссылки`);
    },
  };
};
