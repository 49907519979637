import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import qs from "qs";
import { generateFilter } from "../../../helpers";

export const clubsDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({
      resource,
      pagination,
      filters,
      meta,
      hasPagination = true,
    }) => {
      const { leagueId, seasonId } = meta as {
        leagueId: string;
        seasonId: string;
      };

      const url = `${apiUrl}`;
      const current = pagination?.current || 1;
      const pageSize = pagination?.pageSize || 50;

      const offset = pageSize * (current - 1);
      const limit = pageSize;

      const queryFilters = generateFilter(filters);

      const query = {
        leagueId,
        seasonId,
        ...(hasPagination
          ? {
              limit,
              offset,
            }
          : {}),
      };

      try {
        const { data, headers } = await httpClient.get(
          `${url}?${qs.stringify({ ...query, ...queryFilters })}`
        );
        return {
          data: data,
          // TODO Нужен count
          total: data.location?.count ?? 1000,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    getOne: async ({ resource, id, meta }) => {
      const teamInfoUrl = "/cms/team/info";
      const trainersInfoUrl = "cms/team/trainers";
      const teamMedia = "cms/team/allMedia";

      const { leagueId, teamId, seasonId } = meta as {
        leagueId: number;
        teamId: number;
        seasonId: number;
      };
      const combineUrl = (url: string) => {
        return `${url}?${qs.stringify({ leagueId, teamId, seasonId })}`;
      };

      try {
        const teamFetch = await httpClient.get(combineUrl(teamInfoUrl));
        const trainersFetch = await httpClient.get(combineUrl(trainersInfoUrl));
        const mediaFetch = await httpClient.get(combineUrl(teamMedia));

        const [club, trainers, { media }] = await Promise.all([
          teamFetch.data,
          trainersFetch.data,
          mediaFetch.data,
        ]);

        return {
          data: {
            club,
            trainers: trainers.trainers,
            media,
          },
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
