import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import qs from "qs";

export const leagueInfoDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getOne: async ({ resource, id, meta }) => {
      const url = "/cms/info/league/about";
      try {
        const { data } = await httpClient.get(url);

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    update: async ({ resource, id, variables }) => {
      const url = "/cms/info/league/about";
      try {
        const { data } = await httpClient.post(
          `${url}?${qs.stringify({ ...variables })}`
        );

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
