import { CrudSorting } from "@refinedev/core";

export enum ESortingType {
  ASС = "asc",
  DESC = "desc",
}

export const generateSort = (
  sort?: CrudSorting,
  initField?: string,
  initOrder?: ESortingType
) => {
  let _sort = [initField || "id"]; // default sorting field
  let _order = [initOrder || (ESortingType.DESC as string)]; // default sorting

  if (sort) {
    _sort = [];
    _order = [];

    sort.map((item) => {
      _sort.push(item.field);
      _order.push(item.order);
    });
  }

  return {
    _sort,
    _order,
  };
};
