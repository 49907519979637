import { HttpError, OpenNotificationParams } from "@refinedev/core";
import { AxiosResponse } from "axios";

interface IErrorData {
  errorCode: string;
  message: string;
  field?: string;
  value?: string;
}

interface IErrorException {
  errorCode: string;
  errorMessage: string;
  message: string;
  errors?: Array<IErrorData>;
}

type ErrorKeys = Array<{ errorCode: string; message: string }>;

export interface IErrorRequestData {
  errorKeys?: ErrorKeys;
  callback?: (error: IErrorException | AxiosResponse) => void;
}
type TNotification = (
  data: any,
  values: any,
  resource: string
) => OpenNotificationParams;

interface INotificationData {
  type?: string;
  message?: string;
  description?: string;
}

interface IErrorNotification {
  errorNotification?: TNotification | any;
}
interface IErrorRequest {
  errorData?: IErrorRequestData;
  validateErrors?: boolean;
}

interface ISuccessNotification {
  successNotification?: TNotification | any;
}
interface ISuccessRequest {
  successData?: INotificationData | undefined;
  callback?: (data: any) => void;
}

const _generateErrorMessage = (
  errorBody: AxiosResponse,
  errorKeys: ErrorKeys = []
): { type: string; message: string; description: string } | undefined => {
  const errorMessages: Array<string> = [];

  const matchError = (errorKey: number) => {
    return errorKeys.find((code) => code.errorCode === String(errorKey));
  };

  if (errorBody.status) {
    const matchingError = matchError(errorBody.status);
    if (matchingError) {
      errorMessages.push(matchingError.message);
    }

    return {
      type: "error",
      message: errorMessages.join(", ") || "Повторите попытку снова",
      description: "Произошла ошибка",
    };
  }
};
export const errorNotification = ({
  validateErrors = true,
  errorData = { callback: undefined, errorKeys: [] },
}: Partial<IErrorRequest> = {}): IErrorNotification => {
  const isError = validateErrors
    ? {
        errorNotification: (
          data: HttpError & { errorBody: IErrorException } & {
            response: AxiosResponse;
          }
        ) => {
          const { errorBody, response } = data;
          const { callback, errorKeys } = errorData;

          if (data.statusCode === 401) {
            return {
              type: "error",
              message: "Пользователь не авторизован",
            };
          }
          callback && callback(response);
          if (response?.status) {
            return _generateErrorMessage(response, errorKeys);
          }

          return {
            type: "error",
            message: "Что-то пошло не так",
            description: "Повторите попытку снова",
          };
        },
      }
    : {};
  return {
    ...isError,
  };
};

export const successNotification = ({
  successData,
  callback,
}: ISuccessRequest): ISuccessNotification | undefined => {
  return {
    successNotification: (data: any, values: any, resource: string) => {
      callback && callback(data);
      if (successData) {
        return successData;
      }
    },
  };
};
