export enum MEDIA_TYPE {
  LINK = "LINK",
  PHOTO = "PHOTO",
}

export type MatchMedia = {
  description: string;
  hashTags: string[];
  id: number;
  leagueId: number;
  matchId: number;
  modifiedDate: string;
  title: string;
  videoUrl: string;
  url: string;
  photos: { url: string }[];
  type: MEDIA_TYPE;
};

export enum URL_TYPE {
  BROADCAST = "BROADCAST",
}

export type MatchUrl = {
  id: number;
  typeLink: string;
  gameId: number;
  leagueId: number;
  url: string;
  modifiedDate: string;
  title: string;
  description: string;
  type: URL_TYPE;
};

export type Match = {
  leagueId: number;
  matchId: number;
  seasonId: number;
  season: string;
  tourId: number;
  tour: string;
  date: string;
  stadium: {
    title?: string;
    city?: string;
    viewers?: string;
  };
  judges: {
    name?: string;
    city?: string;
  };
  home: {
    teamId: number;
    name: string;
    score: number;
  };
  guest: {
    teamId: number;
    name: string;
    score: number;
  };
  gambling?: null | string;
  urls: MatchUrl[];
  media: MatchMedia[];
};

export type MatchesGrid = Match[];
