import { Rule } from "antd/lib/form";

export const noSpecialCharValidator: Rule = {
  validator: async (_, value: string) => {
    if (!value || /^[a-zA-Zа-яА-Я0-9_ .,!?;:–—\-ёЁ"]+$/.test(value)) {
      return;
    }
    throw new Error("Поле не должно содержать специальных символов");
  },
};
