export const s3Path = ({
  path,
  width = 30,
  height,
  original = false,
  filters = {},
  fitIn = false,
  thumbor = "",
}: {
  path: string;
  width?: number;
  height?: number;
  filters?: { grayscale?: boolean };
  fitIn?: boolean;
  original?: boolean;
  thumbor: string;
}): string => {
  const _height = height == null ? width : height;
  const filterQueries = [];
  let filterQuery = "";
  let filling = "";

  if (fitIn) {
    filling = "fit-in/";
    filterQueries.push("upscale():fill(blur):quality(60)");
  }

  if (filters.grayscale) {
    filterQueries.push("grayscale()");
  }

  if (filterQueries.length > 0) {
    filterQuery = `/filters:${filterQueries.join(":")}`;
  }

  const isOriginal = original
    ? ""
    : `/${filling}${width}x${_height}${filterQuery}`;

  const renderSlash = path && path[0] == "/" ? "" : "/";

  return `${thumbor}${isOriginal}${renderSlash}${path}`;
};

export function blobToBase64(blob: Blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}
