import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import qs from "qs";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";

export const supportMessagesDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({ meta }: any) => {
      const url = "cms/users/support/messages/all";
      try {
        const query = {
          limit: 10,
          offset: 0,
        };
        const { data } = await httpClient.get(
          `${url}?${qs.stringify({ ...query })}`
        );

        return {
          data: data.messages,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    getOne: async ({ resource, id, meta }) => {
      const { messageId } = meta as { messageId: string };

      const url = `cms/users/support/message`;
      try {
        const query = {
          id: messageId,
        };

        const { data } = await httpClient.get(
          `${url}?${qs.stringify({ ...query })}`
        );

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      meta,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
      };
    }) => {
      const url = `cms/users/support/message/delete?id=${meta.id}`;
      try {
        const { data } = await httpClient.post(url);
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
