import { CrudFilter, useNavigation } from "@refinedev/core";
import { ShowButton, TextField, useTable } from "@refinedev/antd";
import { NewsType } from "../../Lib/types";
import {
  Button,
  Flex,
  Form,
  Select,
  Space,
  Table,
  TablePaginationConfig,
  Tag,
} from "antd";
import { AppImagePreview } from "../../../../components";
import s from "./All-News.module.scss";
import { nameLeagueMapByIndex } from "../../../Leagues/Lib/constants";
import { PinnedDialog } from "../../Pinned-News/Pinned-Dialog/Pinned-Dialog";
import { FC, useEffect, useMemo } from "react";
import { dateFormat, DayMonthYearHoursAndMinutes } from "../../../../helpers";
import { NewsDelete } from "../../News-Delete/News-Delete";
import { EditOutlined } from "@ant-design/icons";

type AllNewsProps = {
  tabKey: string;
};

type TablePaginationProps = TablePaginationConfig & { total: number };

export const AllNews: FC<AllNewsProps> = ({ tabKey }) => {
  const { push } = useNavigation();
  const { tableProps, tableQueryResult, searchFormProps, setFilters } =
    useTable<any>({
      dataProviderName: "newsDataProvider",
      resource: "news",
      syncWithLocation: false,
      queryOptions: {
        enabled: true,
      },
      onSearch: (params: any) => {
        const filters: CrudFilter[] = [];
        const { leagueId } = params;

        filters.push({
          field: "leagueId",
          operator: "null",
          value: leagueId || undefined,
        });

        return filters || [];
      },
    });

  useEffect(() => {
    tabKey === "1" && tableQueryResult.refetch();
  }, [tabKey]);

  const allNews = tableProps.dataSource as unknown as {
    news: NewsType[];
  };
  const tableLimit = useMemo(() => {
    const total = (tableProps.pagination as TablePaginationProps).total;
    return total >= 10;
  }, [tableProps.pagination]);

  const handleRefetchList = () => tableQueryResult.refetch();

  const handleChangeFilter = (value: number) => {
    setFilters([
      {
        value: value || undefined,
        field: "leagueId",
        operator: "null",
      },
    ]);
  };

  const handleNavigateToNews = (id: number) => {
    push(`show/${id}`);
  };
  const handleNavigateToNewsEdit = (id: number) => {
    push(`edit/${id}`);
  };

  return (
    <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
      <Form
        {...searchFormProps}
        initialValues={{
          leagueId: 0,
        }}
        layout="horizontal"
      >
        <Form.Item name="leagueId" label={"Лига"} className={s.input}>
          <Select
            showArrow={true}
            placeholder="Выберите лигу"
            onChange={handleChangeFilter}
          >
            <Select.Option key={"100"} value={100}>
              Первая лига
            </Select.Option>
            <Select.Option key={"200"} value={200}>
              Вторая лига А
            </Select.Option>
            <Select.Option key={"300"} value={300}>
              Вторая лига Б
            </Select.Option>
            <Select.Option key={"0"} value={0}>
              Все
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
      <Table
        {...tableProps}
        dataSource={allNews?.news}
        scroll={{ x: 1600 }}
        rowKey="id"
        locale={{
          emptyText: <div style={{ margin: 56 }}>Новости отсутствуют</div>,
        }}
        showSorterTooltip={false}
        pagination={
          tableLimit
            ? {
                ...tableProps.pagination,
                pageSizeOptions: [10, 20, 50],
              }
            : false
        }
      >
        <Table.Column
          width={200}
          dataIndex={"mainPhotoUrl"}
          key="mainPhotoUrl"
          title={"Главное фото новости"}
          render={(value) => (
            <AppImagePreview
              imageURL={value}
              useS3={true}
              sharedStyles={s.imageUrl}
            />
          )}
        />
        <Table.Column
          width={120}
          dataIndex={"isPublic"}
          key="isPublic"
          title={"Статус"}
          render={(value) => (
            <Tag color={!value ? "orange" : "blue"}>
              {!value ? "Черновик" : "Публикация"}
            </Tag>
          )}
        />
        <Table.Column
          width={160}
          dataIndex={"title"}
          key="title"
          title="Заголовок"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          width={160}
          dataIndex={"leagueId"}
          key="leagueId"
          title={"Лига"}
          render={(value) => (
            <TextField
              value={value ? nameLeagueMapByIndex[`${value}`] : "Все"}
            />
          )}
        />
        <Table.Column
          width={200}
          dataIndex={"hashTags"}
          key="hashTags"
          title={"Хэштеги"}
          render={(value) => (
            <Flex wrap="wrap" gap="small">
              {value.length ? (
                <>
                  {value.map((data: string) => {
                    return (
                      <Tag className={s.tag} key={data}>
                        {data}
                      </Tag>
                    );
                  })}
                </>
              ) : (
                "–"
              )}
            </Flex>
          )}
        />
        <Table.Column
          width={160}
          dataIndex={"newsDate"}
          key="newsDate"
          title={"Дата публикации"}
          render={(value) => (
            <TextField
              value={
                value ? dateFormat(value, DayMonthYearHoursAndMinutes) : "–"
              }
            />
          )}
        />
        <Table.Column<NewsType>
          width={200}
          fixed={"right"}
          title={"Действия"}
          align={"center"}
          dataIndex="actions"
          render={(_, record) => (
            <Space size={8} direction="vertical" style={{ width: "100%" }}>
              <ShowButton
                block
                size="small"
                onClick={() => handleNavigateToNews(record.id)}
              >
                Посмотреть
              </ShowButton>
              <Button
                onClick={() => handleNavigateToNewsEdit(record.id)}
                block
                size="small"
                icon={<EditOutlined />}
              >
                Редактировать
              </Button>
              <PinnedDialog
                newsId={record.id}
                leagueId={record.leagueId === null ? 0 : record.leagueId}
                handleRefetchList={handleRefetchList}
              />
              <NewsDelete title={record.title} id={record.id} />
            </Space>
          )}
        />
      </Table>
    </Space>
  );
};
