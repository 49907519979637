import { Trainer } from "../../../Lib";
import { FC } from "react";
import { Card, Space, Typography, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { AppViewCard } from "../../../../../components";
import { TextField } from "@refinedev/antd";
import s from "./Clubs-Trainers-Info.module.scss";
import { useHorizontalScroll } from "../../../../../hooks";
import { AppImagePreview } from "../../../../../components/App-Image-Preview/app-image-preview.component";

const { Title } = Typography;

type ClubsMainInfoProps = {
  trainers: Trainer[];
};
export const ClubsTrainersInfo: FC<ClubsMainInfoProps> = ({ trainers }) => {
  const scrollRef = useHorizontalScroll();
  return (
    <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
      <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
        <Title level={5}>Тренерский состав</Title>
        <Tooltip title="Тренерский состав грузится из базы ФНЛ. Тренерский состав не редактируется в CMS.">
          <InfoCircleOutlined />
        </Tooltip>
      </div>
      <div className={s.container} ref={scrollRef}>
        <div className={s.scrollContainer}>
          {trainers?.map((trainer, index) => {
            return (
              <Card
                size={"small"}
                key={trainer.name + "_" + index}
                className={s.card}
              >
                <Space
                  style={{ width: "100%" }}
                  size={16}
                  direction={"vertical"}
                >
                  <AppImagePreview imageURL={trainer.photoUrl} useS3={true} />
                  <AppViewCard label={"ФИО:"}>
                    <TextField value={trainer.name} />
                  </AppViewCard>
                  <AppViewCard label={"Должность:"}>
                    <TextField value={trainer.post} />
                  </AppViewCard>
                </Space>
              </Card>
            );
          })}
        </div>
      </div>
    </Space>
  );
};
