import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import qs from "qs";
import { generateFilter } from "../../../helpers";

export const matchesDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({
      resource,
      pagination,
      filters,
      meta,
      hasPagination = true,
    }) => {
      const { leagueId, seasonId, clear } = meta as {
        leagueId: string;
        seasonId: string;
        clear: boolean;
      };

      const url = `${apiUrl}`;
      const current = pagination?.current || 1;
      const pageSize = pagination?.pageSize || 10;

      const offset = pageSize * (current - 1);
      const limit = pageSize;

      const queryFilters = clear ? {} : generateFilter(filters);

      const query = {
        leagueId,
        seasonId,
        ...(hasPagination
          ? {
              limit,
              offset,
            }
          : {}),
      };

      try {
        const { data, headers } = await httpClient.get(
          `${url}?${qs.stringify({ ...query, ...queryFilters })}`
        );
        return {
          data: data.matches,
          total: data.location.count,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    getOne: async ({ resource, id, meta }) => {
      const url = "/cms/match/info";

      const { leagueId, matchId } = meta as {
        leagueId: number;
        matchId: number;
      };

      try {
        const { data } = await httpClient.get(
          `${url}?${qs.stringify({ leagueId, matchId })}`
        );

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
