import { useMatch } from "react-router-dom";
import { List, TextField, useTable } from "@refinedev/antd";
import { Button, Space, Table } from "antd";
import s from "../List/Russtat-List.module.scss";
import { v4 as randomId } from "uuid";
import { useEffect, useState } from "react";
import { AppTitle } from "../../../../components";
import { useCustom, useNavigation } from "@refinedev/core";
import { MapClubs } from "./Map-Clubs/Map-Clubs";
import { ArrowRightOutlined } from "@ant-design/icons";
import { nameLeagueMapByIndex } from "../../../Leagues/Lib/constants";

type RusstatClubsRowType = {
  fnlLeagueId: number;
  fnlLeagueTitle: string;
  fnlTeamCity: string;
  fnlTeamId: number;
  rsLeagueId: number;
  fnlTeamTitle: string;
  rsTeamId: number;
  rsTeamTitle: string;
};

type RusstatClubType = {
  teamId: number;
  teamTitle: string;
};

export const ClubsList = () => {
  const { push } = useNavigation();
  const route = useMatch("/:parent/:russtat/:clubs/:fnlLeagueId/:fnlSeasonId");
  const [datasource, setDatasource] = useState<Array<any>>([]);
  const [rsClubs, setRsClubs] = useState<RusstatClubType[]>([]);

  const { fnlLeagueId, fnlSeasonId } = route!.params;
  const { tableQueryResult, searchFormProps, setFilters } = useTable<any>({
    dataProviderName: "mappingRusstatClubsDataProvider",
    resource: "russtat-clubs",
    queryOptions: {
      enabled: false,
    },
    syncWithLocation: false,
    filters: {
      initial: [
        {
          field: "fnlLeagueId",
          value: fnlLeagueId,
          operator: "null",
        },
        {
          field: "fnlSeasonId",
          value: fnlSeasonId,
          operator: "null",
        },
      ],
    },
  });

  const { data: rsTeamsData, refetch: refetchRsTeams } = useCustom({
    dataProviderName: "mappingRusstatClubsDataProvider",
    url: "team",
    method: "get",
    meta: {
      leagueId: fnlLeagueId,
      seasonId: fnlSeasonId,
    },
  });

  useEffect(() => {
    refetchRsTeams<RusstatClubType[]>().then(({ data }) => {
      const clubs = data?.data as RusstatClubType[];
      setRsClubs(clubs || []);
    });
  }, []);

  const fetchData = async () => {
    const { data } = await tableQueryResult.refetch();
    const clubs = data?.data as RusstatClubsRowType[];

    if (clubs) {
      await setDatasource(clubs);
    }
  };

  useEffect(() => {
    fetchData();
  }, [fnlLeagueId]);

  const handleNavigateToPlayers = (
    fnlTeamId: number,
    fnlSeasonId: number,
    fnlLeagueId: number
  ) => {
    push(`/mapping/russtat/clubs/${fnlLeagueId}/${fnlSeasonId}/${fnlTeamId}`);
  };

  return (
    <List
      headerButtons={<></>}
      breadcrumb={false}
      title={
        <>
          <AppTitle canGoBack>
            Команды ( {nameLeagueMapByIndex[`${fnlLeagueId}`]} ){/*  */}
          </AppTitle>
          <div
            style={{
              color: "grey",
              fontSize: 12,
              padding: 16,
              fontWeight: 400,
            }}
          >
            {`Выбрать команду без маппинга (Выделена красным) → 
              Нажать кнопку "Смапить команду" → 
              Найти в списке соответствующее название в команды в Рустат → 
              Нажать кнопку "Смапить команду"`}
          </div>
        </>
      }
    >
      <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
        <Table<RusstatClubsRowType>
          dataSource={datasource}
          rowClassName={(record, index, indent) =>
            !record.rsTeamId ? s.needMapping : ""
          }
          pagination={false}
          loading={tableQueryResult.isFetching}
          rowKey={() => randomId()}
          locale={{
            emptyText: <div style={{ margin: 56 }}>Команды не найдены</div>,
          }}
          showSorterTooltip={false}
        >
          <Table.Column<RusstatClubsRowType>
            width={100}
            title="Город"
            render={(_, record) => (
              <TextField value={record.fnlTeamCity || "–"} />
            )}
          />
          <Table.Column<RusstatClubsRowType>
            width={100}
            title="Команда FNL"
            render={(_, record) => (
              <TextField value={record.fnlTeamTitle || "–"} />
            )}
          />
          <Table.Column<RusstatClubsRowType>
            width={100}
            title="Команда Рустат"
            render={(_, record) => (
              <TextField value={record.rsTeamTitle || "–"} />
            )}
          />
          <Table.Column<RusstatClubsRowType>
            width={40}
            title={"Действие"}
            align={"center"}
            render={(_, record) => (
              <Space direction={"vertical"}>
                <MapClubs
                  refetchList={fetchData}
                  fnlTeamId={record.fnlTeamId}
                  rsClubs={rsClubs}
                  fnlLeagueId={record.fnlLeagueId}
                  fnlSeasonId={Number(fnlSeasonId)}
                />
                <Button
                  size="small"
                  block
                  onClick={() =>
                    handleNavigateToPlayers(
                      record.fnlTeamId,
                      Number(fnlSeasonId),
                      record.fnlLeagueId
                    )
                  }
                >
                  Смотреть игроков
                  <ArrowRightOutlined />
                </Button>
              </Space>
            )}
          />
        </Table>
      </Space>
    </List>
  );
};
