import { FC, useState } from "react";
import { TextField, useForm } from "@refinedev/antd";
import { AppViewCard } from "../../../../../components";
import { errorNotification, successNotification } from "../../../../../helpers";
import { CloseOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space } from "antd";
import s from "./Clubs-Change-Description.module.scss";
import { ClubsDeleteEntity } from "../Clubs-Delete-Entity/Clubs-Delete-Entity";

type ClubsChangeColorProps = {
  leagueId: number;
  teamId: number;
  description: {
    value: string;
    id: number | null;
  };
  onRefetchCard: () => void;
};

export const ClubsChangeDescription: FC<ClubsChangeColorProps> = ({
  teamId,
  leagueId,
  description,
  onRefetchCard,
}) => {
  const [edit, setEdit] = useState(false);
  const { formProps, saveButtonProps, form, formLoading } = useForm<{
    url: string;
  }>({
    action: description ? "edit" : "create",
    resource: "clubs",
    meta: {
      teamId,
      leagueId,
    },
    dataProviderName: "clubsDescriptionDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: description ? "Описание изменено" : "Описание добавлено",
        type: "success",
      },
      callback: () => {
        setEdit(false);
        onRefetchCard();
      },
    }),
    ...errorNotification({}),
  });

  const handleEdit = () => {
    setEdit(true);
  };

  const handleStopEdit = () => {
    setEdit(false);
    form.setFieldValue("value", description.value);
  };

  const handleRefetchOnDelete = () => {
    onRefetchCard();
    form.setFieldValue("value", null);
  };

  if (!description) {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          gap: 16,
        }}
      >
        <Form
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-start",
            gap: 8,
          }}
          {...formProps}
          initialValues={{
            teamId,
            leagueId,
          }}
          layout={"vertical"}
        >
          <Form.Item name={"teamId"} hidden />
          <Form.Item name={"leagueId"} hidden />
          <Form.Item
            name={"value"}
            label={"Описание:"}
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Необходимо указать описание",
              },
              {
                max: 1000,
                min: 5,
                message: "Не более 1000 символов и не менее 5 символов",
              },
            ]}
          >
            <Input placeholder={"Укажите описание"}></Input>
          </Form.Item>
          <Button className={s.button} type={"primary"} htmlType={"submit"}>
            Добавить описание
          </Button>
        </Form>
      </div>
    );
  }

  return (
    <>
      {!edit ? (
        <AppViewCard label={"Описание:"}>
          <Space
            direction={"horizontal"}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
            size={24}
          >
            <TextField value={description.value} />
            <div className={s.wrapContainer}>
              <EditOutlined
                style={{
                  fontSize: 20,
                  cursor: "pointer",
                  color: "var(--accent-main)",
                }}
                onClick={handleEdit}
              />
              <ClubsDeleteEntity
                onRefetch={handleRefetchOnDelete}
                deleteId={{ leagueId, teamId }}
                message={"Описание успешно удалено"}
                okText={"Удалить описание"}
                modalText={"Вы уверены, что хотите удалить описание?"}
                dataProviderName={"clubsDescriptionDataProvider"}
              />
            </div>
          </Space>
        </AppViewCard>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            gap: 16,
          }}
        >
          <Form
            style={{
              width: "100%",
              display: "flex",
              gap: 8,
            }}
            {...formProps}
            initialValues={{
              id: description.id,
              value: description.value,
            }}
            layout={"horizontal"}
          >
            <Form.Item name={"id"} hidden />
            <Form.Item
              name={"value"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать описание",
                },
                {
                  min: 5,
                  max: 1000,
                  message: "Описание может быть от 5 до 1000 символов",
                },
              ]}
            >
              <Input placeholder={"Укажите описание"}></Input>
            </Form.Item>
            <Button type={"primary"} htmlType={"submit"}>
              Изменить описание
            </Button>
            <div className={s.iconContainer}>
              <CloseOutlined
                style={{
                  fontSize: 20,
                  cursor: "pointer",
                  color: "var(--accent-main)",
                }}
                onClick={handleStopEdit}
              />
            </div>
          </Form>
        </div>
      )}
    </>
  );
};
