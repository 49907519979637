import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";

export const usersImportDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    create: async ({ meta }: any) => {
      const url = "cms/import/users";
      try {
        const { data } = await httpClient.post(url, meta.formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
