import { Col, Row, Space, Typography, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { AppSimpleLink, AppViewCard } from "../../../../../components";
import { TextField } from "@refinedev/antd";
import { Club } from "../../../Lib";
import { FC } from "react";
import { ClubsChangeDescription } from "../Clubs-Change-Description/Clubs-Change-Description";
import { ClubsChangeAchievements } from "../Clubs-Change-Achivements/Clubs-Change-Achievements";

const { Title } = Typography;

type ClubsAdditionalInfoProps = {
  info: Pick<
    Club,
    "achievements" | "description" | "address" | "site" | "leagueId" | "teamId"
  >;
} & {
  leagueName: string;
  onRefetch: () => void;
};

export const ClubsAdditionalInfo: FC<ClubsAdditionalInfoProps> = ({
  info,
  leagueName,
  onRefetch,
}) => {
  return (
    <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
      <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
        <Title level={5}>Дополнительная информация</Title>
        <Tooltip title="Информация отображается в карточке клуба в разделе О клубе">
          <InfoCircleOutlined />
        </Tooltip>
      </div>
      <Row gutter={24}>
        <Col span={24}>
          <ClubsChangeDescription
            description={{ value: info?.description, id: null }}
            onRefetchCard={onRefetch}
            teamId={info?.teamId}
            leagueId={info?.leagueId}
          />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <AppViewCard label={"Лига:"}>
            <TextField value={leagueName ?? "–"} />
          </AppViewCard>
        </Col>
        <Col span={12}>
          <AppViewCard label={"Сайт:"}>
            {info?.site ? (
              <AppSimpleLink link={info?.site}>{info?.site}</AppSimpleLink>
            ) : (
              "–"
            )}
          </AppViewCard>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <AppViewCard label={"Адрес:"}>
            <TextField value={info?.address ?? "–"} />
          </AppViewCard>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <ClubsChangeAchievements
            achievements={info?.achievements}
            onRefetchCard={onRefetch}
            teamId={info?.teamId}
            leagueId={info?.leagueId}
          />
        </Col>
      </Row>
    </Space>
  );
};
