import { useMatch } from "react-router-dom";
import { Show } from "@refinedev/antd";
import { AppTitle } from "../../../components";
import { leagueIndex } from "../Lib";
import { useOne } from "@refinedev/core";
import { errorNotification } from "../../../helpers";
import { Space, Typography } from "antd";
import { Match } from "../Lib/types";
import { MatchesTeams } from "./components/Matches-Teams";
import { MatchesMainInfo } from "./components/Matches-Main-Info";
import { MatchesTranslation } from "./components/Matches-Translation";
import { MatchesMedia } from "./components/Matches-Media";

const { Title } = Typography;
export const MatchesShow = () => {
  const route = useMatch("/:parent/:league/:season/:seasonId/:show/:matchId");

  const matchId = route?.params.matchId;
  const leagueId = leagueIndex[`${route?.params.league}`];

  const { data, isLoading, refetch } = useOne<Match>({
    resource: "matches",
    id: "",
    meta: {
      matchId,
      leagueId,
    },
    queryOptions: {
      enabled: !!(matchId && leagueId),
    },
    dataProviderName: "matchesDataProvider",
    ...errorNotification(),
  });

  const handleRefetchCard = () => {
    refetch();
  };

  const record = data?.data as Match;

  return (
    <Show
      isLoading={isLoading}
      headerButtons={<></>}
      title={
        <AppTitle canGoBack isLoading={isLoading}>
          {`Матч – «${record?.home?.name} / ${record?.guest?.name}»`}
        </AppTitle>
      }
    >
      <Space direction={"vertical"} size={32} style={{ width: "100%" }}>
        <MatchesMainInfo
          date={record?.date}
          stadium={record?.stadium}
          tour={record?.tour}
        />
        <MatchesTeams guest={record?.guest} home={record?.home} />
        <MatchesTranslation
          onRefetchCard={handleRefetchCard}
          matchId={record?.matchId}
          leagueId={record?.leagueId}
          urls={record?.urls}
        />
        <MatchesMedia {...record} onRefetchCard={handleRefetchCard} />
      </Space>
    </Show>
  );
};
