import { Outlet, Route } from "react-router-dom";
import { HashTagsList } from "../HashTags-List/Hash-Tags-List";
import { ShowProvider } from "../../../providers";

const hashTagsRoutes = [
  <Route key={"hash-tags"} path="hash-tags" element={<Outlet />}>
    <Route
      index
      element={
        <ShowProvider>
          <HashTagsList />
        </ShowProvider>
      }
    />
  </Route>,
];

export const HASH_TAGS_ROUTES = hashTagsRoutes.map((route) => route);
