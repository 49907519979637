// Проверка на латинницу
export const englishRegEx = /^[a-zA-Z_]+$/;

// Проевка на отсутвие букв
export const numberRegEx = /[^0-9]/g;

// Проевка на пробелы в начате строки
export const trimRegEx = /^\s+/;

// Хендлер на ввод только чисел
export const regexNumbersHandler = (str: string) => {
  return str.replace(numberRegEx, "");
};

// Хендлер на обрезание пробелов в начале строки
export const trimHandler = (str: string) => {
  return str.replace(trimRegEx, "");
};

// проверка на строку с апперкейсом и нижним подчеркиванием
export function isValidString(str: string): boolean {
  const pattern = /^[A-Z_]+$/;
  return pattern.test(str);
}

export function removeLineBreaks(html: string) {
  return html.replace(/(\r\n|\n|\r)/gm, "");
}
