import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import qs from "qs";

export const seasonsDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({ resource, sort, meta }) => {
      const { leagueId } = meta as { leagueId: string };

      const url = "cms/info/seasonHistory";

      const query = {
        leagueId,
      };

      try {
        const { data, headers } = await httpClient.get(
          `${url}?${qs.stringify({ ...query })}`
        );
        return {
          data,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
  };
