import { FC } from "react";
import { useModal } from "@refinedev/antd";
import { useDelete } from "@refinedev/core";
import { errorNotification, successNotification } from "../../../helpers";
import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";

type HashTagsDeleteProps = {
  tag: string;
};

export const HashTagsDelete: FC<HashTagsDeleteProps> = ({ tag }) => {
  const { modalProps, show, close } = useModal();
  const { mutate: deleteMutate } = useDelete();

  const handleDelete = () => {
    deleteMutate({
      dataProviderName: "hashTagsDataProvider",
      resource: "hashtags",
      id: "",
      meta: {
        tag,
      },
      ...successNotification({
        successData: {
          message: `Хэштег «${tag}» успешно удален`,
          type: "success",
        },
        callback: () => {
          close();
        },
      }),
      ...errorNotification({}),
    });
  };

  return (
    <>
      <CloseOutlined
        onClick={show}
        style={{
          fontSize: 10,
          cursor: "pointer",
        }}
      />
      <Modal
        okText={"Удалить"}
        cancelText={"Отмена"}
        onOk={handleDelete}
        title={"Подтверждение"}
        onCancel={close}
        {...modalProps}
      >
        Вы уверены что хотите удалить хэштег «{tag}»?
        <br />
        <br />
        Он может быть уже прикреплен к Новости, Медиа, Клубу и не будет удален
        из них
      </Modal>
    </>
  );
};
