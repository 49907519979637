import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import qs from "qs";

export const newsPinDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    update: async ({
      resource,
      id,
      variables,
    }: {
      resource: string;
      variables: {
        type: string;
        leagueId: number;
      };
      id: number;
    }) => {
      const url = "/cms/news/unpin";
      try {
        const { data } = await httpClient.post(
          `${url}?${qs.stringify({ ...variables })}`
        );

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    create: async ({
      variables,
      meta,
    }: {
      resource: string;
      variables: {
        type: string;
        pinnedLeague: number;
      };
      meta: { newsId: number; leagueId: number };
    }) => {
      const url = "/cms/news/pin";

      const { newsId, leagueId } = meta;
      const { pinnedLeague, type } = variables;
      try {
        await httpClient.post(url, {
          type,
          newsId,
          leagueId: leagueId === 0 ? null : leagueId,
        });

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
