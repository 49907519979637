import { Outlet, Route } from "react-router-dom";
import { ShowProvider } from "../../../providers";
import { SeasonsList } from "../../Seasons";
import { ClubsList } from "../Clubs-List/Clubs-List";
import { ClubsShow } from "../Clubs-Show/Clubs-Show";

const clubsRoutes = [
  <Route key={"clubs"} path="clubs" element={<Outlet />}>
    <Route key={"first-league"} path="first-league">
      <Route
        index
        element={
          <ShowProvider>
            <SeasonsList />
          </ShowProvider>
        }
      />
      <Route key={"first-league/season"} path={"season/:seasonId"}>
        <Route
          index
          element={
            <ShowProvider>
              <ClubsList />
            </ShowProvider>
          }
        />
        <Route
          path={"show/:clubId"}
          element={
            <ShowProvider>
              <ClubsShow />
            </ShowProvider>
          }
        />
      </Route>
    </Route>

    <Route key={"second-league"} path="second-league">
      <Route
        index
        element={
          <ShowProvider>
            <SeasonsList />
          </ShowProvider>
        }
      />
      <Route key={"second-league/season"} path={"season/:seasonId"}>
        <Route
          index
          element={
            <ShowProvider>
              <ClubsList />
            </ShowProvider>
          }
        />
        <Route
          path={"show/:clubId"}
          element={
            <ShowProvider>
              <ClubsShow />
            </ShowProvider>
          }
        />
      </Route>
    </Route>

    <Route key={"third-league"} path="third-league">
      <Route
        index
        element={
          <ShowProvider>
            <SeasonsList />
          </ShowProvider>
        }
      />
      <Route key={"third-league/season"} path={"season/:seasonId"}>
        <Route
          index
          element={
            <ShowProvider>
              <ClubsList />
            </ShowProvider>
          }
        />
        <Route
          path={"show/:clubId"}
          element={
            <ShowProvider>
              <ClubsShow />
            </ShowProvider>
          }
        />
      </Route>
    </Route>
  </Route>,
];

export const CLUBS_ROUTES = clubsRoutes.map((route) => route);
