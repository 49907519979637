import { Tabs, TabsProps, Typography, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { AllNews } from "./All-News/All-News";
import { PinnedNews } from "../Pinned-News/Pinned-News";
import { CreateButton, List } from "@refinedev/antd";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

const { Title } = Typography;

export const NewsList = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tabKey = searchParams.get("tab") || "1";

  const onChange = (key: string) => {
    navigate(
      {
        search: createSearchParams({
          tab: key,
        }).toString(),
      },
      {
        replace: true,
      }
    );
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Все новости",
      children: <AllNews tabKey={tabKey} />,
    },
    {
      key: "2",
      label: (
        <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
          Закрепленные новости{" "}
          <Tooltip title="Закрепленная новость отображается на главной странице и в начале в разделе Новости.">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      ),
      children: <PinnedNews tabKey={tabKey} />,
    },
  ];

  return (
    <List
      title="Новости"
      resource={"news"}
      breadcrumb={false}
      canCreate={false}
      wrapperProps={{ style: { position: "sticky", top: 24 } }}
      headerButtons={
        <CreateButton resource={"news"}>Создать новость</CreateButton>
      }
    >
      <Tabs items={items} onChange={onChange} activeKey={tabKey} />
    </List>
  );
};
