import { Button, Form, Modal, Select, Space } from "antd";
import { useForm, useModal } from "@refinedev/antd";
import { errorNotification, successNotification } from "../../../../../helpers";
import s from "./Map-Team.module.scss";
import { useState } from "react";

type MapTeamProps = {
  leagueId: number;
  melbetTeam: string;
  fnlTeam: {
    teamId: number;
    teamName: string;
    teamCity: string;
  };
  refetchList: () => void;
  leagueTeam: { title: string; teamId: number; city: string }[];
};

export const MapTeam = (team: MapTeamProps) => {
  const { modalProps, show, close } = useModal();
  const [fnlTeam, setFnlTeam] = useState<string>();

  const { formProps, saveButtonProps, form, formLoading, onFinish } = useForm<{
    url: string;
  }>({
    resource: "melbet",
    meta: {
      ...team,
      fnlTeam,
    },
    dataProviderName: "mappingMelbetDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: "Маппинг прошел успешно",
        type: "success",
      },
      callback: () => {
        close();
        team.refetchList();
      },
    }),
    ...errorNotification({}),
  });

  const handleSelectFnlTeam = (id: number) => {
    const selectFnlTeamTitle = team.leagueTeam?.find(
      (team) => team.teamId === id
    )?.title;
    setFnlTeam(selectFnlTeamTitle);
  };

  return (
    <>
      <Button size="small" onClick={show}>
        Смапить команду
      </Button>
      <Modal
        okText={"Подтвердить"}
        cancelText={"Отмена"}
        title={"Подтвердить действие"}
        onCancel={close}
        footer={null}
        {...modalProps}
      >
        <Form {...formProps} layout={"vertical"} className={s.form}>
          <Form.Item
            name={"fnlTeamId"}
            rules={[
              {
                required: true,
                message: "Необходимо выбрать команду",
              },
            ]}
          >
            <Select
              onChange={handleSelectFnlTeam}
              showArrow={true}
              placeholder="Выберите команду для маппинга"
              allowClear
              showSearch
              optionFilterProp={"label"}
            >
              {team.leagueTeam.map((select) => {
                return (
                  <Select.Option
                    key={select.teamId}
                    value={select.teamId}
                    label={select.title}
                  >
                    {select.title} {select.city && `(${select.city})`}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Space direction={"horizontal"} className={s.formFooter}>
            <Button type={"primary"} htmlType={"submit"}>
              Смапить команду
            </Button>
            <Button onClick={close}>Отмена</Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
};
