import { AimOutlined } from "@ant-design/icons";
import { ResourceProps } from "@refinedev/core";
import { nameLeagueMap } from "./Lib";

export const matchesResource: ResourceProps = {
  name: "matches",
  meta: {
    icon: <AimOutlined />,
    label: "Матчи",
  },
};

export const seasonsResourceLeagueGenerator = (
  type: "first-league" | "second-league" | "third-league"
): ResourceProps => {
  return {
    name: `${type}`,
    list: `/matches/${type}`,
    show: `/matches/${type}/show/:id`,
    create: `/matches/${type}/create`,
    edit: `/matches/${type}/edit/:id`,
    meta: {
      parent: "matches",
      label: nameLeagueMap[type],
    },
  };
};
