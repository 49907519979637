import { FC } from "react";
import { useModal } from "@refinedev/antd";
import { Button, Modal } from "antd";
import s from "./Matches-Album.module.scss";
import { AppImagePreview } from "../../../../../components";
import { FileImageOutlined } from "@ant-design/icons";

type MatchesAlbumProps = {
  photos: { url: string }[];
};

export const MatchesAlbum: FC<MatchesAlbumProps> = ({ photos }) => {
  const { modalProps, show, close } = useModal();

  return (
    <>
      <Button
        type={"default"}
        onClick={show}
        block
        icon={<FileImageOutlined />}
      >
        Посмотреть альбом
      </Button>
      <Modal
        title={"Просмотр альбома"}
        onCancel={close}
        {...modalProps}
        footer={<></>}
      >
        {Boolean(!photos.length) && (
          <div className={s.emptyContainer}>Изображения отсутствуют</div>
        )}
        <div className={s.container}>
          {photos.map((data) => {
            return (
              <AppImagePreview
                key={data.url}
                imageURL={data.url}
                useS3={true}
              />
            );
          })}
        </div>
      </Modal>
    </>
  );
};
