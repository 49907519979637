import { Button, Form, Modal, Select } from "antd";
import { useForm, useModal } from "@refinedev/antd";
import { errorNotification, successNotification } from "../../../../../helpers";
import { FC } from "react";

type ClubsTagsAddProps = {
  teamId?: string;
  leagueId?: string;
  hashTags: string[];
  initialHashTags: string[];
};

export const ClubsTagsAdd: FC<ClubsTagsAddProps> = ({
  leagueId,
  teamId,
  hashTags,
  initialHashTags,
}) => {
  const { modalProps, show, close } = useModal();
  const { formProps, saveButtonProps, form, formLoading } = useForm<{
    tag: string;
  }>({
    action: "create",
    resource: "hashtags",
    meta: {
      leagueId,
      teamId,
    },
    dataProviderName: "clubsTagsDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: "Хэштеги команды изменены",
        type: "success",
      },
      callback: () => {
        close();
        form.resetFields();
      },
    }),
    ...errorNotification({}),
  });
  const selectOptions = initialHashTags?.map((value) => {
    return { value, label: value };
  });

  return (
    <>
      <Button type={"link"} onClick={show}>
        Добавить или изменить хэштег
      </Button>
      <Modal
        title={"Добавление хэштега команды"}
        onCancel={close}
        {...modalProps}
        footer={<></>}
      >
        <Form
          style={{
            width: "100%",
            marginTop: 16,
          }}
          {...formProps}
          initialValues={{
            hashTags,
          }}
          layout={"vertical"}
        >
          <Form.Item name={"hashTags"} label={"Хэштег"}>
            <Select
              placeholder={"Укажите теги"}
              showSearch
              mode={"multiple"}
              options={selectOptions}
              notFoundContent={
                <div
                  style={{
                    width: "100%",
                    height: 100,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Хэштеги не найдены
                </div>
              }
            />
          </Form.Item>
          <Button type={"primary"} htmlType={"submit"}>
            Добавить
          </Button>
        </Form>
      </Modal>
    </>
  );
};
