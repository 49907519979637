import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";

import { DataProvider } from "@refinedev/core";
import axAPI from "../../../../api/axios-instance";

export const mappingMelbetDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({ resource, sort, meta }) => {
      const { leagueId } = meta as { leagueId: string };

      const url = "cms/melbet/teams/all";

      const query = {
        leagueId,
      };

      try {
        const { data, headers } = await httpClient.get(`${url}`);
        return {
          data,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    create: async ({
      resource,
      meta,
      variables,
    }: {
      meta: {
        leagueId: string;
        melbetTeam: string;
        fnlTeam: string;
      };
      resource: string;
      variables: {
        fnlTeam: string;
      };
    }) => {
      const url = "cms/melbet/teams";

      const { melbetTeam, leagueId, fnlTeam } = meta;
      try {
        await httpClient.post(url, {
          melbetTeam,
          leagueId,
          ...variables,
          fnlTeam,
        });

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
