import { FC, ReactNode } from "react";
import s from "./App-Simple-Link.module.scss";

type AppSimpleLinkProps = {
  link: string;
  children: ReactNode;
  sharedStyles?: string;
};

export const AppSimpleLink: FC<AppSimpleLinkProps> = ({
  link,
  children,
  sharedStyles,
}) => {
  const checkLink = () => {
    if (link?.includes("https://")) {
      return link;
    } else {
      return "https://" + link;
    }
  };

  return (
    <a href={checkLink()} target="_blank" rel="noreferrer" className={s.link}>
      {children}
    </a>
  );
};
