import { List, ShowButton, TextField, useTable } from "@refinedev/antd";
import { useNavigation } from "@refinedev/core";
import { Space, Table } from "antd";
import { useMatch } from "react-router-dom";
import { leagueIndex } from "../../Matches/Lib";
import { errorNotification } from "../../../helpers";
import { AppTitle } from "../../../components";
import { Club, ClubRow } from "../Lib";

export const ClubsList = () => {
  const route = useMatch("/:parent/:league/:season/:seasonId");
  const resource = route?.params.league;
  const seasonId = route?.params.seasonId;
  const { push } = useNavigation();

  const { tableProps, searchFormProps } = useTable<ClubRow[]>({
    dataProviderName: "clubsDataProvider",
    resource: "clubs",
    meta: {
      leagueId: leagueIndex[`${resource}`],
      seasonId,
    },
    syncWithLocation: false,
    queryOptions: {
      enabled: true,
    },
    ...errorNotification({
      errorData: {
        errorKeys: [
          {
            errorCode: "channelPoints.invalid",
            message: "Ошибка",
          },
        ],
      },
    }),
  });

  const handleNavigateToMatches = (teamId: number) => {
    push(`show/${teamId}`);
  };

  return (
    <List
      title={<AppTitle canGoBack>Клубы</AppTitle>}
      resource={"clubs"}
      breadcrumb={false}
      canCreate={false}
      wrapperProps={{ style: { position: "sticky", top: 24 } }}
    >
      <Space size={8} direction="vertical" style={{ width: "100%" }}>
        <Table
          {...tableProps}
          // scroll={{ x: 1200 }}
          rowKey="id"
          locale={{
            emptyText: <div style={{ margin: 56 }}>Клубы отсутствуют</div>,
          }}
          showSorterTooltip={false}
          pagination={false}
        >
          <Table.Column<Club>
            dataIndex={"title"}
            key="title"
            title="Название"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column<Club>
            dataIndex={"city"}
            key="city"
            title="Город"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column<Club>
            width={"15%"}
            title={"Действия"}
            align={"center"}
            dataIndex="actions"
            render={(_, record) => (
              <ShowButton
                size="small"
                onClick={() => handleNavigateToMatches(record.teamId)}
              >
                Посмотреть
              </ShowButton>
            )}
          />
        </Table>
      </Space>
    </List>
  );
};
