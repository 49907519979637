import s from "../Matches-List.module.scss";
import { Form, Select } from "antd";
import { errorNotification } from "../../../../helpers";
import { TextField, useTable } from "@refinedev/antd";
import { FC } from "react";

type TourFilterProps = {
  leagueId: number;
  seasonId: number;
  groupId?: number;
};
export const TourFilter: FC<TourFilterProps> = ({
  seasonId,
  leagueId,
  groupId,
}) => {
  const { tableQueryResult: toursData } = useTable<any>({
    dataProviderName: "toursDataProvider",
    ...errorNotification(),
    queryOptions: {
      enabled: Boolean(seasonId) || Boolean(groupId),
    },
    meta: {
      seasonId,
      leagueId,
      groupId,
    },
  });

  const disableTours = !groupId && leagueId !== 100;

  return (
    <Form.Item name="tourId" className={s.inputBlock}>
      <Select
        disabled={disableTours}
        loading={toursData.isLoading}
        allowClear
        showSearch
        placeholder="Выберите тур"
        notFoundContent={"Тур не найден"}
      >
        {toursData.data?.data
          // Не одобряю
          .sort((a, b) => parseInt(a.name, 10) - parseInt(b.name, 10))
          .map((tour) => {
            return (
              <Select.Option key={tour.id} value={tour.id}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <TextField value={tour.name} />
                </div>
              </Select.Option>
            );
          })}
      </Select>
    </Form.Item>
  );
};
