import axios from "axios";
import { HttpError } from "@refinedev/core";

const axAPI = axios.create({
  withCredentials: false,
});

axAPI.interceptors.request.use(
  (config: any) => {
    const token = localStorage.getItem("auth");
    const isRefreshToken = config.url.includes("refresh");
    if (token && !isRefreshToken) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    } else {
      config.headers = {};
    }

    return config;
  },
  (error) => {
    if (error.code === "ERR_NETWORK") {
      return Promise.reject({ message: "Отсутствует подключение к серверу" });
    }

    return Promise.reject(error as any);
  }
);

axAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const customError: HttpError = {
      ...error,
      message: error.response?.data,
      statusCode: error.response?.status,
      errorBody: error.response.data,
    };

    return Promise.reject(customError);
  }
);

export default axAPI;
