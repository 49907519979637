import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import qs from "qs";
import { generateFilter } from "../../../helpers";

export const toursDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({ resource, sort, meta, filters }) => {
      const { leagueId, seasonId, groupId } = meta as {
        leagueId: string;
        seasonId: string;
        groupId: string;
      };

      const url = "cms/info/toursBySeason";

      const query = {
        leagueId,
        seasonId,
        groupId,
      };

      const queryFilters = generateFilter(filters);

      try {
        const { data, headers } = await httpClient.get(
          `${url}?${qs.stringify({ ...query, ...queryFilters })}`
        );
        return {
          data,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
  };
