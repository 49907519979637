import { Route } from "react-router-dom";
import { ShowProvider } from "../../../providers";
import { UsersList } from "../Users-List";

const usersRoutes = [
  <Route
    key={"users"}
    path="users"
    element={
      <ShowProvider>
        <UsersList />
      </ShowProvider>
    }
  />,
];

export const USERS_ROUTES = usersRoutes.map((route) => route);
