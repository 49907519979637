import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import qs from "qs";

export const clubsDescriptionDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    update: async ({ resource, id, variables, meta }) => {
      const updateUrl = "/cms/team/description";

      try {
        const { data } = await httpClient.post(updateUrl, {
          ...variables,
          ...meta,
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    create: async ({ resource, variables, meta }) => {
      const updateUrl = "/cms/team/description";
      try {
        const { data } = await httpClient.post(updateUrl, {
          ...variables,
          ...meta,
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      resource,
      id,
      meta,
    }: {
      id: number;
      resource: string;
      meta: { leagueId: number; teamId: number };
    }) => {
      const url = "/cms/team/description/delete";
      const { leagueId, teamId } = meta;
      try {
        const { data } = await httpClient.post(
          `${url}?${qs.stringify({ leagueId, teamId })}`
        );
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
