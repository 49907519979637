import { Route } from "react-router-dom";
import { ShowProvider } from "../../../providers";
import { LeaguesList } from "../Leagues-List";

const leaguesRoutes = [
  <Route
    key={"league"}
    path="league"
    element={
      <ShowProvider>
        <LeaguesList />
      </ShowProvider>
    }
  />,
];

export const LEAGUES_ROUTES = leaguesRoutes.map((route) => route);
