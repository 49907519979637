import { ResourceProps } from "@refinedev/core";
import { OrderedListOutlined } from "@ant-design/icons";

export const newsResource: ResourceProps = {
  name: "news",
  meta: {
    icon: <OrderedListOutlined />,
    label: "Новости",
  },
  list: "/news?tab=1",
  create: "/news/create",
  show: "/news/show/:id",
  edit: "/news/edit/:id",
};
