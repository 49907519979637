import { ResourceProps } from "@refinedev/core";
import { UserOutlined } from "@ant-design/icons";

export const partnersResource: ResourceProps = {
  name: "partners",
  meta: {
    icon: <UserOutlined />,
    label: "Партнеры",
  },
  list: "/partners",
  create: "/partners/create",
  show: "/partners/:id",
};
