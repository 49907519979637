import { Rule } from "antd/lib/form";

export const noSpaceValidator: Rule = {
  validator: async (_, value: string) => {
    if (!value || !value.includes(" ")) {
      return;
    }
    throw new Error("Поле не должно содержать пробелов");
  },
};
