import { Button, Form, Input, Modal, Tooltip } from "antd";
import { useForm, useModal } from "@refinedev/antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { errorNotification, successNotification } from "../../../helpers";
import { noSpaceValidator, tagsValidator } from "../../../validators";

export const HashTagsAdd = () => {
  const { modalProps, show, close } = useModal();
  const { formProps, saveButtonProps, form, formLoading } = useForm<{
    tag: string;
  }>({
    action: "create",
    resource: "hashtags",
    dataProviderName: "hashTagsDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: "Хэштег добавлен",
        type: "success",
      },
      callback: () => {
        close();
        form.resetFields();
      },
    }),
    ...errorNotification({
      errorData: {
        errorKeys: [
          {
            errorCode: "409",
            message: "Такой хэштег уже существует",
          },
        ],
      },
    }),
  });

  const onAfterClose = () => {
    form.resetFields();
  };

  return (
    <>
      <Button type={"link"} onClick={show}>
        Добавить хэштег
      </Button>
      <Modal
        afterClose={onAfterClose}
        title={"Добавление хэштега"}
        onCancel={close}
        {...modalProps}
        footer={<></>}
      >
        <Form
          style={{
            width: "100%",
            marginTop: 16,
          }}
          {...formProps}
          layout={"vertical"}
        >
          <Form.Item
            name={"tag"}
            label={
              <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
                Хэштеги{" "}
                <Tooltip
                  title="Хэштег может быть от 3 до 30 символов. Поле не должно содержать
            специальных символов (можно - и _). При удалении хэштега из раздела Хештеги, он может остаться прикрепленным в разделах Новости, Медиа, Клуб."
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            }
            rules={[
              {
                required: true,
                message: "Необходимо указать хэштег",
              },
              {
                min: 3,
                max: 30,
                message: "Хэштег может быть от 3 до 30 символов",
              },
              noSpaceValidator,
              tagsValidator,
            ]}
          >
            <Input placeholder={"Укажите хэштег"}></Input>
          </Form.Item>
          <Button type={"primary"} htmlType={"submit"}>
            Добавить
          </Button>
        </Form>
      </Modal>
    </>
  );
};
