import { Show, useForm } from "@refinedev/antd";
import { Button, Col, Form, Input, Row, Select, Space, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useMatch } from "react-router-dom";
import { v4 as randomId } from "uuid";

import { errorNotification, successNotification } from "../../../helpers";
import { AppImageUploader, AppTitle, EFileTypes } from "../../../components";
import { PartnersType } from "../Lib";
import { noSpecialCharValidator } from "../../../validators";
import { PartnersDelete } from "../Partners-Delete/Partners-Delete";

export const PartnersShow = () => {
  const route = useMatch("/:parent/:id");
  const id = Number(route?.params.id);
  const { formProps, saveButtonProps, form, formLoading, onFinish } =
    useForm<PartnersType>({
      resource: "partners",
      action: id ? "edit" : "create",
      meta: {
        id,
      },
      dataProviderName: "partnersDataProvider",
      redirect: "list",
      ...successNotification({
        successData: {
          message: id ? "Партнер изменен" : "Партнер добавлен",
          type: "success",
        },
      }),
      ...errorNotification({}),
    });

  return (
    <Show
      isLoading={formLoading}
      title={
        <AppTitle isLoading={formLoading} canGoBack>
          {id ? formProps.initialValues?.name : "Добавить партнера"}
        </AppTitle>
      }
      breadcrumb={false}
      headerButtons={
        <Space direction={"horizontal"}>
          {Boolean(id) && (
            <PartnersDelete
              name={formProps.initialValues?.name}
              id={id}
              type={"page"}
            />
          )}
        </Space>
      }
      footerButtons={null}
      goBack={false}
    >
      <Form {...formProps} layout={"vertical"}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name={"photoUrl"}
              label={
                <>
                  Иконка&nbsp;
                  <Tooltip title="Рекомендуется загружать изображения с одинаковым разрешением (например 800*600)">
                    <InfoCircleOutlined />
                  </Tooltip>
                </>
              }
              rules={[
                {
                  required: true,
                  message: "Укажите изображение",
                },
              ]}
            >
              <AppImageUploader
                type="PARTNERS"
                entityId={`${randomId()}`}
                serviceType={EFileTypes.PARTNERS}
                cropperProps={{
                  modalTitle: "Редактировать иконку",
                }}
                uploadProps={{
                  multiple: false,
                  children: "Иконка",
                  listType: "picture-card",
                }}
              />
            </Form.Item>
            <Form.Item
              name={"name"}
              label={"Название:"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать название",
                },
                {
                  max: 30,
                  min: 3,
                  message: "Не более 30 символов и не менее 3 символов",
                },
                noSpecialCharValidator,
              ]}
            >
              <Input placeholder={"Укажите название"}></Input>
            </Form.Item>
            <Form.Item
              name={"link"}
              label={"Ссылка на сайт:"}
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать ссылку на сайт",
                },
              ]}
            >
              <Input placeholder={"Укажите ссылку на сайт"}></Input>
            </Form.Item>
            <Form.Item name="leagueId" label={"Лига"}>
              <Select showArrow={true} placeholder="Выберите лигу">
                <Select.Option key={"100"} value={100}>
                  Первая лига
                </Select.Option>
                <Select.Option key={"200"} value={200}>
                  Вторая лига А
                </Select.Option>
                <Select.Option key={"300"} value={300}>
                  Вторая лига Б
                </Select.Option>
                <Select.Option key={"0"} value={0}>
                  Лига ФНЛ
                </Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="partnerType" label={"Тип партнера"}>
              <Select showArrow={true} placeholder="Выберите тип партнера">
                <Select.Option
                  key={"Титульный партнер"}
                  value={"Титульный партнер"}
                >
                  Титульный партнер
                </Select.Option>
                <Select.Option
                  key={"Официальный партнер"}
                  value={"Официальный партнер"}
                >
                  Официальный партнер
                </Select.Option>
                <Select.Option
                  key={"Информационный партнер"}
                  value={"Информационный партнер"}
                >
                  Информационный партнер
                </Select.Option>
                <Select.Option
                  key={"Технический партнер"}
                  value={"Технический партнер"}
                >
                  Технический партнер
                </Select.Option>{" "}
                <Select.Option
                  key={"Банковский партнер"}
                  value={"Банковский партнер"}
                >
                  Банковский партнер
                </Select.Option>
                <Select.Option
                  key={"Автомобильный партнер"}
                  value={"Автомобильный партнер"}
                >
                  Автомобильный партнер
                </Select.Option>
                <Select.Option key={"Трэвел партнер"} value={"Трэвел партнер"}>
                  Трэвел партнер
                </Select.Option>
                <Select.Option key={"Бизнес партнер"} value={"Бизнес партнер"}>
                  Бизнес партнер
                </Select.Option>
              </Select>
            </Form.Item>
            <Space>
              <Button type={"primary"} htmlType={"submit"}>
                {id ? "Отредактировать" : "Добавить партнера"}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Show>
  );
};
