import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import qs from "qs";
import { generateFilter } from "../../../helpers";

export const hashTagsDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({ filters }) => {
      const url = "/cms/tags";
      const queryFilters = generateFilter(filters);

      const filterQuery = qs.stringify(queryFilters);
      try {
        const { data } = await httpClient.get(`${url}`, {
          // headers: {
          //   "Access-Control-Allow-Origin": "*",
          // },
        });
        return {
          data: data.tags,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    getOne: async ({ resource, id, meta }) => {
      const url = `${apiUrl}/${resource}/${id}`;
      try {
        const { data } = await httpClient.get(url);

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    update: async ({ resource, id, variables }) => {
      const url = `${apiUrl}/${resource}/${id}`;
      try {
        const { data } = await httpClient.put(url, { ...variables });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    create: async ({ resource, variables, metaData, meta }) => {
      const url = "/cms/tags/addTag";
      try {
        await httpClient.post(url, {
          ...variables,
        });

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({ resource, id, meta }) => {
      const url = "/cms/tags/removeTag";
      try {
        const { data } = await httpClient.post(url, {
          ...meta,
        });
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
