import React, { FC } from "react";
import SunEditor from "suneditor-react";
import { SunEditorReactProps } from "suneditor-react/dist/types/SunEditorReactProps";
import { richTextTemplate } from "./rich-text-template";
import { removeLineBreaks } from "../../helpers";
import s from "./app-rich-text.module.scss";

interface IAppRichTextProps {
  richTextProps?: SunEditorReactProps;
  editorTemplate?: string[][];
  readonly?: boolean;
  stickyToolbar?: string | number | undefined;
}

export const AppRichText: FC<IAppRichTextProps> = ({
  richTextProps,
  editorTemplate,
  readonly,
  ...props
}) => {
  const { value, onChange } = props as any;
  const validateHTMLTags = (data: string) => {
    const html = removeLineBreaks(data);
    const regExp = /<([^>\s]+)[^>]*>.*?<\/\1>|<[^/]+\/>/g;
    const matches = html.match(regExp);

    if (matches && matches.length > 0) {
      for (let i = 0; i < matches.length; i++) {
        const tempElement = document.createElement("div");
        tempElement.innerHTML = matches[i];
        const textContent =
          tempElement.textContent && tempElement.textContent.trim();
        if (textContent && textContent.length > 0) {
          return html;
        }
      }
    }
    return undefined;
  };

  const handleEditorStateChange = (html: string) => {
    onChange(validateHTMLTags(html));
  };

  return (
    <SunEditor
      defaultValue={value}
      disable={readonly}
      onChange={handleEditorStateChange}
      height={"400"}
      lang={"ru"}
      setOptions={{
        className: s.richText,
        buttonList: editorTemplate || richTextTemplate.default,
        imageFileInput: true,
      }}
      {...richTextProps}
    />
  );
};
