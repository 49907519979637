import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";

export const socialLinksDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    create: async ({
      variables,
      meta,
    }: {
      resource: string;
      variables: { typeLink: string; url: string };
      meta: {
        leagueId: number;
        teamId: number;
      };
    }) => {
      const url = "/cms/team/social";

      const { leagueId, teamId } = meta;
      try {
        const { data } = await httpClient.post(url, {
          leagueId,
          teamId,
          link: variables.url,
          linkType: variables.typeLink,
        });
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({ resource, id, meta }) => {
      const { leagueId, teamId, linkType } = meta as {
        leagueId: number;
        teamId: number;
        linkType: string;
      };

      const url = `/cms/team/social/delete?leagueId=${leagueId}&teamId=${teamId}&linkType=${linkType}`;

      try {
        const { data } = await httpClient.post(url);
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
