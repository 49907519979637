import { Show, useForm } from "@refinedev/antd";
import { Col, Form, Input, Row } from "antd";
import { useMatch } from "react-router-dom";

import { errorNotification } from "../../../helpers";
import { AppImagePreview, AppTitle } from "../../../components";
import { SupportMessagesType } from "../Lib";
import s from "./Support-Image.module.scss";

export const SupportMessage = () => {
  const route = useMatch("/:parent/:id");
  const messageId = route?.params.id;
  const { formProps, formLoading, onFinish } = useForm<SupportMessagesType>({
    resource: "support-messages",
    action: "edit",
    meta: {
      messageId,
    },
    dataProviderName: "supportMessagesDataProvider",
    redirect: "list",
    ...errorNotification({}),
  });

  return (
    <Show
      isLoading={formLoading}
      title={
        <AppTitle isLoading={formLoading} canGoBack>
          {formProps?.initialValues?.title}
        </AppTitle>
      }
      breadcrumb={false}
      footerButtons={null}
      goBack={false}
    >
      <Form {...formProps} layout={"vertical"}>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name={"id"}
              label={"Номер обращения:"}
              style={{ width: "100%" }}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              name={"dateMessage"}
              label={"Дата обращения:"}
              style={{ width: "100%" }}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              name={"userName"}
              label={"Имя:"}
              style={{ width: "100%" }}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              name={"phone"}
              label={"Телефон:"}
              style={{ width: "100%" }}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              name={"email"}
              label={"Email:"}
              style={{ width: "100%" }}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item name="message" label="Текст:">
              <Input.TextArea disabled rows={10} />
            </Form.Item>

            {formProps?.initialValues?.imagesUrl.length > 0 ? (
              <Form.Item name="imagesUrl" label="Изображения:">
                {formProps?.initialValues?.imagesUrl.map((image: string) => {
                  return (
                    <AppImagePreview
                      key={image}
                      imageURL={image}
                      useS3={true}
                      sharedStyles={s.imageUrl}
                    />
                  );
                })}
              </Form.Item>
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Form>
    </Show>
  );
};
