import { Button, Form, Modal, Select, Space } from "antd";
import { useForm, useModal } from "@refinedev/antd";
import { errorNotification, successNotification } from "../../../../../helpers";
import s from "./Map-Season.module.scss";

type MapTeamProps = {
  leagueId: string;
  fnlLeagueId: number;
  fnlLeagueTitle: string;
  fnlSeasonId: number;
  fnlSeasonTitle: string;
  rsLeagueId: number;
  rsLeagueTitle: string;
  rsSeasonId: number;
  rsSeasonTitle: string;
  refetchList: () => void;
  rsLeagues: { id: string; title: string }[];
  rsSeasons: { id: string; title: string }[];
};

export const MapSeason = (season: MapTeamProps) => {
  const { modalProps, show, close } = useModal();

  const { formProps, saveButtonProps, form, formLoading, onFinish } = useForm<{
    url: string;
  }>({
    resource: "russtat",
    meta: {
      ...season,
    },
    dataProviderName: "mappingRusstatDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: "Маппинг прошел успешно",
        type: "success",
      },
      callback: () => {
        close();
        season.refetchList();
      },
    }),
    ...errorNotification({}),
  });

  return (
    <>
      <Button size="small" onClick={show} block>
        Смапить сезон
      </Button>
      <Modal
        okText={"Подтвердить"}
        cancelText={"Отмена"}
        title={"Подтвердить действие"}
        onCancel={close}
        footer={null}
        {...modalProps}
      >
        <Form {...formProps} layout={"vertical"} className={s.form}>
          <Form.Item
            name={"rsLeagueId"}
            rules={[
              {
                required: true,
                message: "Необходимо выбрать лигу",
              },
            ]}
          >
            <Select
              showArrow={true}
              placeholder="Выберите лигу Рустат"
              allowClear
              showSearch
              optionFilterProp={"label"}
            >
              {season.rsLeagues?.map((select) => {
                return (
                  <Select.Option
                    key={select.id}
                    value={select.id}
                    label={select.title}
                  >
                    {select.title}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name={"rsSeasonId"}
            rules={[
              {
                required: true,
                message: "Необходимо выбрать сезон",
              },
            ]}
          >
            <Select
              showArrow={true}
              placeholder="Выберите сезон Рустат"
              allowClear
              showSearch
              optionFilterProp={"label"}
            >
              {season.rsSeasons?.map((select) => {
                return (
                  <Select.Option
                    key={select.id}
                    value={select.id}
                    label={select.title}
                  >
                    {select.title}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <Space direction={"horizontal"} className={s.formFooter}>
            <Button type={"primary"} htmlType={"submit"}>
              Смапить сезоны
            </Button>
            <Button onClick={close}>Отмена</Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
};
