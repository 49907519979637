import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import qs from "qs";

export const clubsColorDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    update: async ({
      resource,
      variables,
      id,
      meta,
    }: {
      resource: string;
      id: number;
      variables: { color: string; leagueId: number; teamId: number };
      meta: {
        teamId: number;
        leagueId: number;
      };
    }) => {
      const updateUrl = "/cms/team/color";
      const { leagueId, teamId } = meta;

      try {
        const { data } = await httpClient.post(updateUrl, {
          ...variables,
          color: variables.color,
          leagueId,
          teamId,
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    create: async ({
      resource,
      variables,
      meta,
    }: {
      resource: string;
      variables: { color: string; leagueId: number; teamId: number };
      meta: {
        teamId: number;
        leagueId: number;
      };
    }) => {
      const updateUrl = "/cms/team/color";
      const { leagueId, teamId } = meta;

      try {
        const { data } = await httpClient.post(updateUrl, {
          color: variables.color,
          leagueId,
          teamId,
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      resource,
      id,
      meta,
    }: {
      id: number;
      resource: string;
      meta: { leagueId: number; teamId: number };
    }) => {
      const url = "/cms/team/color/delete";
      const { leagueId, teamId } = meta;
      try {
        const { data } = await httpClient.post(
          `${url}?${qs.stringify({ leagueId, teamId })}`
        );
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
