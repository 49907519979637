import { List, ShowButton, TextField, useTable } from "@refinedev/antd";
import { Space, Table } from "antd";
import dayjs from "dayjs";

import { MessageDelete } from "../Message-Delete/Message-Delete";
import { errorNotification } from "../../../helpers";
import { SupportMessagesType } from "../Lib";
import { useNavigation } from "@refinedev/core";

export const SupportMessagesList = () => {
  const { push } = useNavigation();

  const { tableProps, searchFormProps } = useTable<SupportMessagesType[]>({
    dataProviderName: "supportMessagesDataProvider",
    resource: "support-messages",
    syncWithLocation: false,
    queryOptions: {
      enabled: true,
    },
    ...errorNotification(),
  });

  console.log(tableProps);

  const handleNavigateToPartner = (id: string) => {
    push(`/support-messages/${id}`);
  };

  return (
    <List
      title={
        <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
          Обращения{" "}
        </div>
      }
      resource={"support-messages"}
      breadcrumb={false}
      canCreate={false}
      wrapperProps={{ style: { position: "sticky", top: 24 } }}
    >
      <Table
        {...tableProps}
        scroll={{ x: 1200 }}
        rowKey="id"
        locale={{
          emptyText: <div style={{ margin: 56 }}>Партнеры отсутствуют</div>,
        }}
        showSorterTooltip={false}
        pagination={false}
      >
        <Table.Column<SupportMessagesType>
          width={100}
          dataIndex={"number"}
          key="number"
          title="Номер обращения"
          render={(_, record) => <TextField value={record.id} />}
        />
        <Table.Column<SupportMessagesType>
          width={180}
          dataIndex={"title"}
          key="title"
          title="Тема обращения"
          render={(_, record) => <TextField value={record.title} />}
        />
        <Table.Column<SupportMessagesType>
          width={220}
          dataIndex={"message"}
          key="message"
          title="Текст обращения"
          render={(_, record) => <TextField value={record.message} />}
        />
        <Table.Column<SupportMessagesType>
          width={180}
          dataIndex={"date"}
          key="date"
          title="Дата обращения"
          render={(_, record) => {
            const formatedDate = dayjs(record.dateMessage).format(
              "DD.MM.YYYY HH:mm:ss"
            );
            return <TextField value={formatedDate} />;
          }}
        />
        <Table.Column<SupportMessagesType>
          width={140}
          dataIndex={"name"}
          fixed={"right"}
          key="name"
          title="Действия"
          render={(_, record) => (
            <Space size={8} direction="vertical" style={{ width: "100%" }}>
              <ShowButton
                onClick={() => handleNavigateToPartner(String(record.id))}
                size={"small"}
                block
              >
                Смотреть
              </ShowButton>
              <MessageDelete id={Number(record.id)} title={record.title} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
