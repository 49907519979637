import s from "../Clubs-Main-Info/Clubs-Main-Info.module.scss";
import {
  AppImagePreview,
  AppImageUploader,
  AppViewCard,
  EFileTypes,
} from "../../../../../components";
import { FC, useEffect } from "react";
import { EditOutlined } from "@ant-design/icons";
import { useForm, useModal } from "@refinedev/antd";
import { Button, Form, Modal, Space, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { errorNotification, successNotification } from "../../../../../helpers";
import { v4 as randomId } from "uuid";
import { useDelete } from "@refinedev/core";

type ClubsBackgroundType = {
  background: string;
  leagueId: number;
  teamId: number;
  onRefetchCard: () => void;
};
export const ClubsBackground: FC<ClubsBackgroundType> = ({
  teamId,
  leagueId,
  background,
  onRefetchCard,
}) => {
  const { modalProps, show, close } = useModal();
  const { mutate } = useDelete();
  const { formProps, saveButtonProps, form, formLoading } = useForm<{
    url: string;
  }>({
    resource: "clubs",
    dataProviderName: "clubsBackgroundDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: "Фон добавлен",
        type: "success",
      },
      callback: () => {
        close();
        onRefetchCard();
      },
    }),
    ...errorNotification({}),
  });

  const openEdit = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    show();
  };

  const onDeleteBackground = () => {
    mutate({
      resource: "clubs",
      dataProviderName: "clubsBackgroundDataProvider",
      id: "",
      ...successNotification({
        successData: {
          message: "Фон удален",
          type: "success",
        },
        callback: () => {
          close();
          onRefetchCard();
        },
      }),
      ...errorNotification({}),
      meta: {
        teamId,
        leagueId,
      },
    });
  };
  useEffect(() => {
    form.setFieldValue("background", background);
  }, [background]);

  return (
    <div className={s.previewContainer}>
      <div
        className={s.previewBgEdit}
        title={"Редактировать"}
        onClick={openEdit}
      >
        <EditOutlined style={{ color: "var(--danger)" }} />
      </div>
      <AppViewCard label={"Фон клуба"} sharedStyles={s.logo}>
        <AppImagePreview sharedStyles={s.logo} imageURL={background} useS3 />
      </AppViewCard>
      <Modal {...modalProps} footer={<></>} title={"Редактировать фон"}>
        <Form
          {...formProps}
          initialValues={{
            ...formProps.initialValues,
            teamId,
            leagueId,
            background,
          }}
          layout={"vertical"}
        >
          <Form.Item name={"teamId"} hidden />
          <Form.Item name={"leagueId"} hidden />
          <Form.Item
            name={"background"}
            label={
              <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
                Фон клуба{" "}
                <Tooltip title="Фон клуба используется в качестве бэкграунда в Карточке клуба. Пропорции загружаемого изображения: 9*16.">
                  <InfoCircleOutlined />
                </Tooltip>
              </div>
            }
            rules={[
              {
                required: true,
                message: "Укажите фон клуба",
              },
            ]}
          >
            <AppImageUploader
              entityId={`${randomId()}`}
              type="TEAM"
              serviceType={EFileTypes.CLUBS_BG}
              cropperProps={{
                modalTitle: "Редактировать изображение",
              }}
              uploadProps={{
                multiple: false,
                children: "Изображение",
                listType: "picture-card",
              }}
            />
          </Form.Item>
          <Space className={s.modalFooter}>
            <Button type={"primary"} htmlType={"submit"}>
              Добавить фон
            </Button>
            {background && (
              <Button
                type={"primary"}
                danger
                htmlType={"button"}
                onClick={onDeleteBackground}
              >
                Удалить фон
              </Button>
            )}
          </Space>
        </Form>
      </Modal>
    </div>
  );
};
