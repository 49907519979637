import { Button, Flex, Form, Select, Space, Table, Tag } from "antd";

import s from "./Pinned-News.module.scss";
import { AppImagePreview } from "../../../components";
import { nameLeagueMapByIndex } from "../../Leagues/Lib/constants";
import { ShowButton, TextField, useTable } from "@refinedev/antd";
import { CrudFilter, useNavigation } from "@refinedev/core";
import { NewsType, PinInfoType } from "../Lib/types";
import { UnPinnedDialog } from "./UnPinned-Dialog/UnPinned-Dialog";
import { FC, useEffect } from "react";
import { dateFormat, DayMonthYearHoursAndMinutes } from "../../../helpers";
import { EditOutlined } from "@ant-design/icons";

type PinnedNewsProps = {
  tabKey: string;
};
export const PinnedNews: FC<PinnedNewsProps> = ({ tabKey }) => {
  const { push } = useNavigation();
  const { tableProps, searchFormProps, tableQueryResult, setFilters } =
    useTable<NewsType>({
      dataProviderName: "newsDataProvider",
      resource: "news/getPinNews",
      syncWithLocation: false,
      queryOptions: {
        enabled: true,
      },
      onSearch: (params: any) => {
        const filters: CrudFilter[] = [];
        const { leagueId } = params;

        filters.push({
          field: "leagueId",
          operator: "null",
          value: leagueId || undefined,
        });

        return filters || [];
      },
    });

  useEffect(() => {
    tabKey === "2" && tableQueryResult.refetch();
  }, [tabKey]);

  const pinnedNews = tableProps.dataSource as unknown as {
    mainNews: NewsType[];
  };

  const handleRefetchList = () => tableQueryResult.refetch();
  const handleNavigateToNews = (id: number) => {
    push(`show/${id}`);
  };
  const handleNavigateToNewsEdit = (id: number) => {
    push(`edit/${id}`);
  };

  const handleChangeFilter = (value: number) => {
    setFilters([
      {
        value: value || undefined,
        field: "leagueId",
        operator: "null",
      },
    ]);
  };
  const sorter = (a: PinInfoType, b: PinInfoType) => {
    return a.leagueId - b.leagueId;
  };

  const pinPositions = {
    ["FIRST"]: "Первая позиция закрепления",
    ["SECOND"]: "Вторая позиция закрепления",
  };

  return (
    <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
      <Form
        {...searchFormProps}
        initialValues={{
          leagueId: 0,
        }}
        layout="horizontal"
      >
        <Form.Item name="leagueId" label={"Лига"} className={s.input}>
          <Select
            showArrow={true}
            placeholder="Выберите лигу"
            onChange={handleChangeFilter}
          >
            <Select.Option key={"100"} value={100}>
              Первая лига
            </Select.Option>
            <Select.Option key={"200"} value={200}>
              Вторая лига А
            </Select.Option>
            <Select.Option key={"300"} value={300}>
              Вторая лига Б
            </Select.Option>
            <Select.Option key={"0"} value={0}>
              Все
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
      <Table
        {...tableProps}
        dataSource={pinnedNews?.mainNews}
        scroll={{ x: 1600 }}
        rowKey="id"
        locale={{
          emptyText: (
            <div style={{ margin: 56 }}>Закрепленные новости отсутствуют</div>
          ),
        }}
        showSorterTooltip={false}
        pagination={false}
      >
        <Table.Column
          width={260}
          dataIndex={"mainPhotoUrl"}
          key="mainPhotoUrl"
          title={"Главное фото новости"}
          render={(value) => (
            <AppImagePreview
              imageURL={value}
              useS3={true}
              sharedStyles={s.imageUrl}
            />
          )}
        />
        <Table.Column
          width={120}
          dataIndex={"isPublic"}
          key="isPublic"
          title={"Статус"}
          render={(value) => (
            <Tag color={!value ? "orange" : "blue"}>
              {!value ? "Черновик" : "Публикация"}
            </Tag>
          )}
        />
        <Table.Column
          width={160}
          dataIndex={"title"}
          key="title"
          title="Заголовок"
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          width={160}
          dataIndex={"leagueId"}
          key="leagueId"
          title={"Лига"}
          render={(value) => (
            <TextField
              value={value ? nameLeagueMapByIndex[`${value}`] : "Все"}
            />
          )}
        />
        <Table.Column
          width={200}
          dataIndex={"hashTags"}
          key="hashTags"
          title={"Хэштеги"}
          render={(value) => (
            <Flex wrap="wrap" gap="small">
              {value.length ? (
                <>
                  {value.map((data: string) => {
                    return (
                      <Tag className={s.tag} key={data}>
                        {data}
                      </Tag>
                    );
                  })}
                </>
              ) : (
                "–"
              )}
            </Flex>
          )}
        />
        <Table.Column
          width={160}
          dataIndex={"newsDate"}
          key="newsDate"
          title={"Дата публикации"}
          render={(value) => (
            <TextField
              value={
                value ? dateFormat(value, DayMonthYearHoursAndMinutes) : "–"
              }
            />
          )}
        />
        <Table.Column<NewsType>
          width={200}
          dataIndex={"pinInfo"}
          key="pinInfo"
          title={"Закреплены в лигах"}
          render={(value) => (
            <Space direction={"vertical"} size={4}>
              {value.sort(sorter).map((pins: PinInfoType) => {
                return (
                  <Space key={pins.leagueId} direction={"vertical"} size={2}>
                    <TextField
                      className={s.pinnedLeague}
                      value={nameLeagueMapByIndex[`${pins.leagueId}`]}
                    />
                    <span className={s.pinnedPosition}>
                      {pinPositions[pins.pinType]}
                    </span>
                  </Space>
                );
              })}
            </Space>
          )}
        />
        <Table.Column<NewsType>
          width={200}
          fixed={"right"}
          title={"Действия"}
          align={"center"}
          dataIndex="actions"
          render={(_, record) => (
            <Space size={8} direction="vertical" style={{ width: "100%" }}>
              <ShowButton
                size="small"
                block
                onClick={() => handleNavigateToNews(record.id)}
              >
                Посмотреть
              </ShowButton>
              <Button
                onClick={() => handleNavigateToNewsEdit(record.id)}
                block
                size="small"
                icon={<EditOutlined />}
              >
                Редактировать
              </Button>
              <UnPinnedDialog
                newsId={record.id}
                handleRefetchList={handleRefetchList}
                leaguesVariants={record.pinInfo}
              />
            </Space>
          )}
        />
      </Table>
    </Space>
  );
};
