import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import qs from "qs";

export const partnersDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({ resource, sort, meta }) => {
      const { leagueId } = meta as { leagueId: string };

      const url = "cms/partners/all";

      const query = {
        leagueId,
      };

      try {
        const { data, headers } = await httpClient.get(
          `${url}?${qs.stringify({ ...query })}`
        );
        return {
          data: data.partners,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    getOne: async ({ resource, id, meta }) => {
      const url = `cms/partners/${id}`;
      try {
        const { data: response } = await httpClient.get(url);

        const data = await {
          ...response,
          leagueId: response.leagueId === null ? 0 : response.leagueId,
        };

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    update: async ({
      resource,
      id,
      meta,
      variables,
    }: {
      resource: string;
      id: number;
      meta: { id: string };
      variables: any;
    }) => {
      const url = "cms/partners/add";
      const { id: updateId } = meta;
      try {
        const { data } = await httpClient.post(url, {
          ...variables,
          leagueId: variables.leagueId === 0 ? null : variables.leagueId,
          id: updateId,
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    create: async ({
      resource,
      variables,
    }: {
      resource: string;
      id: number;
      meta: { id: string };
      variables: any;
    }) => {
      const url = "cms/partners/add";
      try {
        await httpClient.post(url, {
          ...variables,
          leagueId: variables.leagueId === 0 ? null : variables.leagueId,
        });

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({
      resource,
      id,
      meta,
    }: {
      resource: string;
      id: number;
      meta: {
        id: number;
      };
    }) => {
      const url = `cms/partners/remove/${meta.id}`;
      try {
        const { data } = await httpClient.post(url);
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
