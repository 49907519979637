import {
  Card,
  Form,
  FormInstance,
  message,
  Space,
  Typography,
  Tooltip,
} from "antd";
import {
  AppImageUploader,
  AppRichText,
  EFileTypes,
  richTextTemplate,
} from "../../../components";
import { v4 as randomId } from "uuid";
import {
  DeleteOutlined,
  PlusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import s from "./News-Create.module.scss";
import { FC } from "react";
import { useWatch } from "antd/es/form/Form";
const { Paragraph } = Typography;

type NewsBlockCreateProps = {
  setDisabled?: boolean;
  form: FormInstance<any>;
  isPublic: boolean;
};

export const NewsBlockCreate: FC<NewsBlockCreateProps> = ({
  setDisabled,
  form,
  isPublic,
}) => {
  const watchBlocks = useWatch("news", form);

  const hideBlockAdd = Boolean(watchBlocks?.length > 9) || setDisabled;

  return (
    <Space size={16} direction="vertical" style={{ width: "100%" }}>
      <Paragraph className={s.newsBlockUpperHeader}>
        <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
          {isPublic && <span className={s.requiredStar}>*</span>}
          Новостные блоки{" "}
          <Tooltip title="Максимального количество блоков в новости - 10">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      </Paragraph>
      <Form.List
        name="news"
        rules={
          isPublic
            ? [
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      message.error("Должен быть хотя бы один новостной блок");
                      return Promise.reject(
                        new Error("Должен быть хотя бы один новостной блок")
                      );
                    }
                  },
                },
              ]
            : []
        }
      >
        {(fields, { add, remove }) => (
          <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
            {fields.map((field, index, value) => {
              return (
                <Card
                  key={field.key}
                  title={
                    <div className={s.newsBlockHeader}>
                      {`Блок новости ${index + 1}`}
                      {!setDisabled && (
                        <div
                          className={s.appImageDelete}
                          onClick={(event) => {
                            remove(field.name);
                          }}
                        >
                          <DeleteOutlined
                            style={{
                              color: "var(--danger)",
                            }}
                          />
                        </div>
                      )}
                    </div>
                  }
                >
                  <Form.Item
                    name={[field.name, "photoUrl"]}
                    label={"Изображение"}
                  >
                    <AppImageUploader
                      type="NEWS"
                      disabled={setDisabled}
                      entityId={`${randomId()}`}
                      serviceType={EFileTypes.NEWS}
                      cropperProps={{
                        modalTitle: "Редактировать изображение",
                      }}
                      uploadProps={{
                        multiple: false,
                        children: "Изображение блока новости",
                        listType: "picture-card",
                      }}
                    />
                  </Form.Item>
                  <Form.Item name={[field.name, "text"]} label={"Контент"}>
                    <AppRichText
                      readonly={setDisabled}
                      editorTemplate={richTextTemplate.backofficeNews}
                      richTextProps={{
                        placeholder: "Укажите контент новости",
                        setOptions: {
                          buttonList: richTextTemplate.backofficeNews,
                          font: ["TT Norms Pro", "TT Runs"],
                          colorList: [
                            "#FFFFFF",
                            "#F6F6F6",
                            "#9EA8B4",
                            "#5D636A",
                          ],
                          linkProtocol: "https://",
                          linkRelDefault: {
                            default: "no-follow",
                          },
                          defaultStyle:
                            "font-family: TT Norms Pro, sans-serif; background-color: #383838; color: #FFFFFF; font-size: 16px;",
                          fontSize: [
                            10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
                          ],
                        },
                      }}
                    />
                  </Form.Item>
                </Card>
              );
            })}
            {!hideBlockAdd && (
              <div className={s.addImageContainer} onClick={() => add()}>
                <PlusOutlined />
                Добавить блок новости
              </div>
            )}
          </Space>
        )}
      </Form.List>
    </Space>
  );
};
