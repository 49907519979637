import { Button, Form, Modal, Radio, Space, Tooltip } from "antd";
import { TextField, useForm, useModal } from "@refinedev/antd";
import { FC } from "react";
import { errorNotification, successNotification } from "../../../../helpers";
import { TagOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { nameLeagueMapByIndex } from "../../../Leagues/Lib/constants";

type PinnedDialogProps = {
  newsId: number;
  leagueId: number;
  handleRefetchList: () => void;
};
export const PinnedDialog: FC<PinnedDialogProps> = ({
  newsId,
  leagueId,
  handleRefetchList,
}) => {
  const { modalProps, show, close } = useModal();

  const { formProps, saveButtonProps, form, formLoading } = useForm<{
    url: string;
  }>({
    action: "create",
    resource: "newsPin",
    meta: {
      newsId,
      leagueId,
    },
    dataProviderName: "newsPinDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: "Новость закреплена",
        type: "success",
      },
      callback: () => {
        close();
        form.resetFields();
        handleRefetchList();
      },
    }),
    ...errorNotification({}),
  });

  const getSelect = (): { key: number; value: string }[] => {
    return Object.keys(nameLeagueMapByIndex)
      .filter((key) => key !== "0")
      .map((index) => {
        return {
          key: Number(index),
          value: nameLeagueMapByIndex[`${index}`],
        };
      });
  };

  return (
    <>
      <Button size={"small"} onClick={show} block icon={<TagOutlined />}>
        Закрепить
      </Button>
      <Modal
        {...modalProps}
        onCancel={close}
        footer={<></>}
        // title={"Закрепление новости"}
        title={
          <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
            Закрепление новости{" "}
            <Tooltip title="Максимальное количество закрепленных новостей - 2 на лигу.">
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        }
      >
        <Form
          style={{
            width: "100%",
          }}
          {...formProps}
          layout={"vertical"}
        >
          <Space direction={"vertical"} size={16}>
            <Space direction={"vertical"} size={16}>
              <TextField
                value={`Закрепить в лиге: ${
                  nameLeagueMapByIndex[`${leagueId}`]
                }`}
              />

              <Form.Item
                name={"type"}
                initialValue={"FIRST"}
                label={"Порядок закрепления"}
              >
                <Radio.Group>
                  <Radio value={"FIRST"}>Первая</Radio>
                  <Radio value={"SECOND"}>Вторая</Radio>
                </Radio.Group>
              </Form.Item>
            </Space>
            <Button htmlType={"submit"} type={"primary"}>
              Закрепить новость
            </Button>
          </Space>
        </Form>
      </Modal>
    </>
  );
};
