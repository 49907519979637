import React, { createContext, ReactNode } from "react";
import axiosInstance from "../api/axios-instance";

interface IEnvContextType {
  BASE_URL: string;
  THUMBOR_URL: string;
}

const EnvContext = createContext<IEnvContextType>({
  BASE_URL: "",
  THUMBOR_URL: "",
});

interface IEnvProvider {
  config: IEnvContextType;
  children: ReactNode;
}

const EnvProvider: React.FC<IEnvProvider> = ({ config, children }) => {
  axiosInstance.defaults.baseURL = `${config.BASE_URL}/api/v1` || "/api/v1";

  return (
    <EnvContext.Provider value={{ ...config }}>{children}</EnvContext.Provider>
  );
};

export { EnvContext, EnvProvider };
