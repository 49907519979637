import { Col, Row, Space, Typography } from "antd";
import { AppViewCard } from "../../../../components";
import { TextField } from "@refinedev/antd";
import { Match } from "../../Lib/types";
import { FC } from "react";
import { dateFormat, DayMonthYear } from "../../../../helpers";

const { Title } = Typography;

type MatchesMainInfoProps = Pick<Match, "stadium" | "date" | "tour">;
export const MatchesMainInfo: FC<MatchesMainInfoProps> = ({
  stadium,
  tour,
  date,
}) => {
  const showStadium = stadium?.title && stadium?.city;

  return (
    <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
      <Title level={5}>Основная информация</Title>
      <Row gutter={8}>
        <Col span={8}>
          <AppViewCard label={"Дата:"}>
            <TextField value={dateFormat(date, DayMonthYear)} />
          </AppViewCard>
        </Col>
        <Col span={8}>
          <AppViewCard label={"Стадион:"}>
            <TextField
              value={showStadium ? `${stadium?.city} ${stadium?.title}` : "–"}
            />
          </AppViewCard>
        </Col>
        <Col span={8}>
          <AppViewCard label={"Тур:"}>
            <TextField value={tour} />
          </AppViewCard>
        </Col>
      </Row>
    </Space>
  );
};
