import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { TextField, useForm, useModal } from "@refinedev/antd";
import { Match, MatchUrl } from "../../Lib/types";
import { FC, ReactNode, useState } from "react";
import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { errorNotification, successNotification } from "../../../../helpers";
import { useDelete } from "@refinedev/core";
import { AppViewCard } from "../../../../components";
import { MatchesTranslationAdd } from "./Matches-Translation-Add";
import { translationsTypesSelect } from "../../Lib";
import differenceWith from "lodash.differencewith";
import { useWatch } from "antd/es/form/Form";
import { streamValidator } from "../../../../validators";

const { Title } = Typography;

type MatchesTranslationProps = Pick<Match, "matchId" | "leagueId" | "urls"> & {
  onRefetchCard: () => void;
};
type SelectType = { key: string; value: string };

export const MatchesTranslation: FC<MatchesTranslationProps> = ({
  matchId,
  urls,
  leagueId,
  onRefetchCard,
}) => {
  const [collapse, setCollapse] = useState<string>();
  const matchesTypes = urls?.map((data) => {
    return { key: data.typeLink, value: data.typeLink };
  });

  const comparator = (obj1: SelectType, obj2: SelectType) =>
    obj1.key === obj2.key;
  const intersectionSelectOption = differenceWith(
    translationsTypesSelect,
    matchesTypes,
    comparator
  );

  return (
    <Space direction={"vertical"} size={16} style={{ width: "100%" }}>
      <Space
        direction={"horizontal"}
        size={16}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Title level={5}>Ссылки на трансляцию</Title>
        <MatchesTranslationAdd
          matchId={matchId}
          intersectionSelectOption={intersectionSelectOption}
          leagueId={leagueId}
          onRefetchCard={onRefetchCard}
        />
      </Space>

      <Row gutter={[16, 16]}>
        {urls?.map((translation) => {
          return (
            <Col span={8} key={translation.id}>
              <EditTranslation
                intersectionSelectOption={intersectionSelectOption}
                translation={translation}
                matchId={matchId}
                leagueId={leagueId}
                onRefetchCard={onRefetchCard}
              >
                <TextField value={translation.url || "–"} />
              </EditTranslation>
            </Col>
          );
        })}
      </Row>
    </Space>
  );
};

type EditTranslationProps = {
  children?: ReactNode;
  translation: MatchUrl;
  matchId: number;
  leagueId: number;
  intersectionSelectOption: SelectType[];
  onRefetchCard: () => void;
};
const EditTranslation: FC<EditTranslationProps> = ({
  children,
  translation,
  matchId,
  leagueId,
  intersectionSelectOption,
  onRefetchCard,
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const { modalProps, show, close } = useModal();
  const { mutate: deleteMutate } = useDelete();

  const { formProps, saveButtonProps, form, formLoading } = useForm<{
    url: string;
  }>({
    action: "edit",
    resource: "translations",
    dataProviderName: "translationsDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: "Ссылка на трансляцию обновлена",
        type: "success",
      },
      callback: () => {
        setEdit(false);
        onRefetchCard();
      },
    }),
    ...errorNotification({}),
  });

  const handleSetEdit = () => setEdit(true);

  const deleteTranslation = () => {
    deleteMutate({
      dataProviderName: "translationsDataProvider",
      resource: "translations",
      id: translation?.id,
      meta: {
        broadcastId: translation?.id,
      },
      ...successNotification({
        successData: {
          message: "Ссылка на трансляцию успешно удалена",
          type: "success",
        },
        callback: () => {
          onRefetchCard();
          close();
        },
      }),
      ...errorNotification({}),
    });
  };

  const mapTitle = translationsTypesSelect.find(
    (select) => select.key === translation.typeLink
  )?.value;

  const translationOption = [
    { key: translation.typeLink, value: translation.typeLink },
  ];
  const typeLink = useWatch("typeLink", form);

  return (
    <Card size={"small"}>
      <Space direction={"vertical"} size={24} style={{ width: "100%" }}>
        <Space
          direction={"horizontal"}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Title level={5}>Трансляция - {mapTitle}</Title>
          <Space size={8}>
            {!edit ? (
              <EditOutlined
                onClick={handleSetEdit}
                style={{
                  fontSize: 20,
                  cursor: "pointer",
                  color: "var(--accent-main)",
                }}
              />
            ) : (
              <CloseOutlined onClick={() => setEdit(false)} />
            )}

            <DeleteOutlined
              onClick={show}
              style={{
                color: "var(--danger)",
                fontSize: 20,
                cursor: "pointer",
              }}
            />
          </Space>
        </Space>
        {!edit ? (
          <>
            {/*<AppViewCard label={"Тип трансляции:"}>*/}
            {/*  {translation.title}*/}
            {/*</AppViewCard>*/}
            <AppViewCard label={"Ссылка на трансляцию:"}>
              {children}
            </AppViewCard>
          </>
        ) : (
          <Form
            {...formProps}
            initialValues={{
              url: translation.url,
              id: translation.id,
              typeLink: translation.typeLink,
            }}
            layout={"vertical"}
          >
            <Form.Item hidden name={"id"} />
            <Form.Item
              name={"typeLink"}
              label={"Тип трансляции:"}
              rules={[
                {
                  required: true,
                  message: "Необходимо выбрать тип трансляции",
                },
              ]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Выберите тип трансляции"
                notFoundContent={"Трансляция не найдена"}
              >
                {[...intersectionSelectOption, ...translationOption].map(
                  (translation) => {
                    return (
                      <Select.Option
                        key={translation.key}
                        value={translation.key}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <TextField value={translation.value} />
                        </div>
                      </Select.Option>
                    );
                  }
                )}
              </Select>
            </Form.Item>
            <Form.Item
              name={"url"}
              label={"Ссылка на трансляцию"}
              rules={[
                {
                  required: true,
                  message: "Необходимо указать ссылку",
                },
                streamValidator(typeLink),
              ]}
            >
              <Input placeholder={"Укажите ссылку"}></Input>
            </Form.Item>
            <Button type={"primary"} htmlType={"submit"}>
              Обновить трансляцию
            </Button>
          </Form>
        )}
      </Space>

      <Modal
        okText={"Удалить трансляцию"}
        cancelText={"Отмена"}
        onOk={deleteTranslation}
        title={"Подтверждение"}
        onCancel={close}
        {...modalProps}
      >
        Вы уверены что хотите удалить ссылку?
      </Modal>
    </Card>
  );
};
