import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";
import axAPI from "../../../api/axios-instance";
import { DataProvider } from "@refinedev/core";
import qs from "qs";
import { MatchMedia } from "../Lib/types";

export const matchesMediaDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    update: async ({
      variables,
    }: {
      id: number;
      resource: string;
      variables: MatchMedia;
    }) => {
      const updateUrl = "/cms/match/updateMedia";

      try {
        const { data } = await httpClient.post(updateUrl, {
          ...variables,
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    create: async ({
      resource,
      variables,
      meta,
    }: {
      resource: string;
      variables: MatchMedia;
      meta: {
        matchId: number;
        leagueId: number;
      };
    }) => {
      const updateUrl = "/cms/match/addMedia";

      const { matchId, leagueId } = meta;

      try {
        const { data } = await httpClient.post(updateUrl, {
          ...variables,
          matchId,
          leagueId,
          // url: variables.url || "",
          // photos: variables.photos || [],
        });

        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    deleteOne: async ({ resource, id, meta }) => {
      const url = "/cms/match/removeMedia";

      try {
        const { data } = await httpClient.post(
          `${url}?${qs.stringify({ mediaId: id })}`
        );
        return {
          data,
        };
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
  };
