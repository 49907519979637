import { MatchMedia, MEDIA_TYPE } from "../../Lib/types";
import { FC, useState } from "react";
import { TextField, useForm, useModal } from "@refinedev/antd";
import { useDelete } from "@refinedev/core";
import { errorNotification, successNotification } from "../../../../helpers";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  message,
  Modal,
  Select,
  Space,
  Tag,
  Typography,
} from "antd";
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import {
  AppImagePreview,
  AppImageUploader,
  AppViewCard,
  EFileTypes,
} from "../../../../components";
import { MatchesAlbum } from "./Matches-Album/Matches-Album";
import s from "./Matches-Media.module.scss";
import { v4 as randomId } from "uuid";
import {
  arrayLengthValidator,
  noSpecialCharValidator,
} from "../../../../validators";
import { mediaTypeSelect } from "../../Lib";

const { Title, Paragraph } = Typography;

type MatchesMediaCardProps = {
  media: MatchMedia;
  count: number;
  onRefetchCard: () => void;
  selectOptions?: { value: string; label: string }[];
};

export const MatchesMediaCard: FC<MatchesMediaCardProps> = ({
  media,
  count,
  onRefetchCard,
  selectOptions,
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const { modalProps, show, close } = useModal();

  const { mutate: deleteMutate } = useDelete();
  const { formProps, saveButtonProps, form, formLoading } = useForm<{
    url: string;
  }>({
    action: "edit",
    resource: "matchesMedia",
    dataProviderName: "matchesMediaDataProvider",
    redirect: false,
    ...successNotification({
      successData: {
        message: `Медиа ${count + 1} обновлен`,
        type: "success",
      },
      callback: () => {
        setEdit(false);
        onRefetchCard();
      },
    }),
    ...errorNotification({}),
  });

  const watchType = Form.useWatch("type", formProps.form);

  const handleChangeType = (value: string) => {
    if (value === media.type) {
      form.setFieldValue("url", media.url);
    } else {
      form.setFieldValue("url", null);
    }
  };

  const handleEdit = () => setEdit(true);
  const handleStopEdit = () => {
    setEdit(false);
    form.setFieldValue("url", media.url);
  };

  const deleteMedia = () => {
    deleteMutate({
      dataProviderName: "matchesMediaDataProvider",
      resource: "matchesMedia",
      id: media.id,
      ...successNotification({
        successData: {
          message: `«Медиа ${count + 1}» удалена`,
          type: "success",
        },
        callback: () => {
          onRefetchCard();
          close();
        },
      }),
      ...errorNotification({}),
    });
  };

  const watchLength = Form.useWatch("photos", formProps.form);
  const stopAdding = watchLength?.length <= 9;

  return (
    <>
      <Col span={8}>
        <Card size={"small"}>
          <Space
            direction={"horizontal"}
            size={16}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Space
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 16,
              }}
            >
              <Title level={5}>Медиа {count + 1}</Title>
              <DeleteOutlined
                onClick={show}
                style={{
                  color: "var(--danger)",
                  fontSize: 20,
                  cursor: "pointer",
                }}
              />
            </Space>
            {edit ? (
              <Space onClick={handleStopEdit} style={{ cursor: "pointer" }}>
                <CloseOutlined />
                Закрыть
              </Space>
            ) : (
              <EditOutlined
                style={{
                  fontSize: 20,
                  cursor: "pointer",
                  color: "var(--accent-main)",
                }}
                onClick={handleEdit}
              />
            )}
          </Space>
          {!edit ? (
            <Space
              direction={"vertical"}
              size={16}
              style={{
                width: "100%",
              }}
            >
              {media.type === MEDIA_TYPE.PHOTO && (
                <>
                  <AppViewCard label={"Обложка альбома"}>
                    <AppImagePreview imageURL={media.url} useS3={true} />
                  </AppViewCard>
                  <MatchesAlbum photos={media.photos} />
                </>
              )}
              {media.type === MEDIA_TYPE.LINK && (
                <>
                  <AppViewCard label={"Превью"}>
                    <AppImagePreview imageURL={media.url} useS3={true} />
                  </AppViewCard>
                  <AppViewCard label={"Ссылка"}>
                    <TextField value={media.videoUrl} />
                  </AppViewCard>
                </>
              )}
              <AppViewCard label={"Заголовок:"}>
                <TextField value={media.title ?? "–"} />
              </AppViewCard>
              <AppViewCard label={"Хэштеги:"}>
                <Space direction={"horizontal"} wrap size={2}>
                  {media.hashTags.map((tag, index) => {
                    return (
                      <Tag bordered key={index} style={{ marginBottom: 8 }}>
                        {tag}
                      </Tag>
                    );
                  })}
                </Space>
              </AppViewCard>
            </Space>
          ) : (
            <Form
              style={{
                width: "100%",
              }}
              {...formProps}
              initialValues={{ ...media }}
              layout={"vertical"}
            >
              <Form.Item name={"id"} hidden />
              <Form.Item name={"type"} label={"Тип медиа"} hidden>
                <Select
                  placeholder="Выберите тип трансляции"
                  notFoundContent={"Тип не найден"}
                  onChange={handleChangeType}
                >
                  {mediaTypeSelect.map((translation) => {
                    return (
                      <Select.Option
                        key={translation.key}
                        value={translation.key}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <TextField value={translation.value} />
                        </div>
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              {watchType === "PHOTO" && (
                <>
                  <Form.Item
                    label={"Обложка альбома"}
                    name={"url"}
                    rules={[
                      {
                        required: true,
                        message: "Укажите изображение",
                      },
                    ]}
                  >
                    <AppImageUploader
                      type="MATCH"
                      entityId={`${media.matchId}/${randomId()}`}
                      serviceType={EFileTypes.MATCHES}
                      cropperProps={{
                        modalTitle: "Редактировать изображение",
                      }}
                      uploadProps={{
                        multiple: false,
                        children: "Прикрепите фото",
                        listType: "picture-card",
                      }}
                    />
                  </Form.Item>
                  <Space
                    size={8}
                    direction="vertical"
                    style={{ width: "100%" }}
                  >
                    <Paragraph className={s.newsBlockUpperHeader}>
                      <span className={s.requiredStar}>*</span> Фото альбома
                    </Paragraph>

                    <Form.List
                      name="photos"
                      rules={[
                        {
                          validator: async (_, names) => {
                            if (!names || names.length < 1) {
                              message.error(
                                "Должен быть хотя бы одно изображение"
                              );
                              return Promise.reject(
                                new Error(
                                  "Должен быть хотя бы одно изображение"
                                )
                              );
                            }
                          },
                        },
                      ]}
                    >
                      {(fields, { add, remove }) => (
                        <>
                          <div className={s.imageContainer}>
                            {fields.map((field) => (
                              <div
                                key={field.key}
                                className={s.appImageWrapper}
                              >
                                <div
                                  className={s.appImageDelete}
                                  onClick={(event) => {
                                    remove(field.name);
                                    event.stopPropagation();
                                  }}
                                >
                                  <CloseOutlined
                                    style={{
                                      color: "var(--danger)",
                                    }}
                                  />
                                </div>
                                <Form.Item
                                  name={[field.name, "url"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: (
                                        <span style={{ fontSize: "10px" }}>
                                          Укажите изображение
                                        </span>
                                      ),
                                    },
                                  ]}
                                >
                                  <AppImageUploader
                                    type="MATCH"
                                    entityId={`${media.matchId}/${randomId()}`}
                                    serviceType={EFileTypes.MATCHES}
                                    cropperProps={{
                                      modalTitle: "Редактировать изображение",
                                    }}
                                    uploadProps={{
                                      multiple: false,
                                      children: "Прикрепите фото",
                                      listType: "picture-card",
                                    }}
                                  />
                                </Form.Item>
                              </div>
                            ))}
                            {stopAdding && (
                              <div
                                className={s.addImageContainer}
                                onClick={() => add()}
                              >
                                <PlusOutlined />
                                Добавить фото
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </Form.List>
                  </Space>
                </>
              )}

              {watchType === "LINK" && (
                <>
                  <Form.Item
                    label={"Превью"}
                    name={"url"}
                    rules={[
                      {
                        required: true,
                        message: "Укажите превью",
                      },
                    ]}
                  >
                    <AppImageUploader
                      type="MATCH"
                      entityId={`${media.matchId}/${randomId()}`}
                      serviceType={EFileTypes.MATCHES}
                      cropperProps={{
                        modalTitle: "Редактировать изображение",
                      }}
                      uploadProps={{
                        multiple: false,
                        children: "Прикрепите фото",
                        listType: "picture-card",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={"videoUrl"}
                    label={"Ссылка"}
                    rules={[
                      {
                        required: true,
                        message: "Необходимо указать ссылку",
                      },
                    ]}
                  >
                    <Input placeholder={"Укажите ссылку"}></Input>
                  </Form.Item>
                </>
              )}
              <Form.Item
                name={"title"}
                label={"Заголовок"}
                rules={[
                  {
                    required: true,
                    message: "Необходимо указать заголовок",
                  },
                  {
                    max: 100,
                    min: 5,
                    message: "Не более 100 символов и не менее 5 символов",
                  },
                  noSpecialCharValidator,
                ]}
              >
                <Input placeholder={"Укажите заголовок"}></Input>
              </Form.Item>
              <Form.Item
                name={"hashTags"}
                label={"Хэштеги"}
                rules={[
                  arrayLengthValidator(3, "Хэштегов не может быть больше 3"),
                ]}
              >
                <Select
                  placeholder={"Укажите теги"}
                  showSearch
                  mode={"multiple"}
                  options={selectOptions}
                  notFoundContent={
                    <div
                      style={{
                        width: "100%",
                        height: 100,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      Укажите хэштеги
                    </div>
                  }
                />
              </Form.Item>

              <Button type={"primary"} htmlType={"submit"}>
                Изменить медиа
              </Button>
            </Form>
          )}
        </Card>
      </Col>
      <Modal
        okText={"Удалить медиа"}
        cancelText={"Отмена"}
        onOk={deleteMedia}
        title={"Подтверждение"}
        onCancel={close}
        {...modalProps}
      >
        Вы уверены что хотите удалить Медиа {count + 1}?
      </Modal>
    </>
  );
};
