import s from "../Matches-List.module.scss";
import { Form, Select } from "antd";
import { errorNotification } from "../../../../helpers";
import { TextField, useTable } from "@refinedev/antd";
import { FC } from "react";

type GroupFilterProps = {
  leagueId: number;
  seasonId: number;
  onChangeGroup: (data: any) => void;
};
export const GroupFilter: FC<GroupFilterProps> = ({
  seasonId,
  leagueId,
  onChangeGroup,
}) => {
  const { tableQueryResult: groupsData } = useTable<any>({
    dataProviderName: "groupsDataProvider",
    ...errorNotification(),
    queryOptions: {
      enabled: Boolean(seasonId),
    },
    meta: {
      seasonId,
      leagueId,
    },
  });

  return (
    <Form.Item name="groupId" className={s.inputBlock}>
      <Select
        loading={groupsData.isLoading}
        allowClear
        showSearch
        placeholder="Выберите группу"
        notFoundContent={"Группа не найдена"}
        onChange={onChangeGroup}
      >
        {groupsData.data?.data.map((group) => {
          return (
            <Select.Option key={group.id} value={group.id}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <TextField value={group.name} />
              </div>
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};
