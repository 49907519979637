import { IDataContextProvider } from "@refinedev/core/dist/contexts/data/IDataContext";
import { AxiosInstance } from "axios";

import { DataProvider } from "@refinedev/core";
import qs from "qs";
import axAPI from "../../../../api/axios-instance";
import { generateFilter } from "../../../../helpers";

export const mappingRusstatClubsDataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axAPI
): DataProvider =>
  <IDataContextProvider>{
    getList: async ({ resource, sort, meta, filters }) => {
      const url = "cms/rs/team/mapping";
      const queryFilters = generateFilter(filters);
      try {
        const { data, headers } = await httpClient.get(
          `${url}?${qs.stringify({ ...queryFilters })}`
        );
        return {
          data,
        };
      } catch (e) {
        return Promise.reject(e);
      }
    },
    create: async ({
      resource,
      meta,
      variables,
    }: {
      meta: {
        fnlLeagueId: number;
        fnlSeasonId: number;
        fnlTeamId: number;
      };
      resource: string;
      variables: {
        rsTeamId: number;
      };
    }) => {
      const url = "cms/rs/team/mapping";
      const { fnlLeagueId, fnlTeamId, fnlSeasonId } = meta;
      try {
        await httpClient.post(
          `${url}?${qs.stringify({ fnlLeagueId, fnlSeasonId })}`,
          {
            fnlLeagueId,
            fnlTeamId,
            ...variables,
          }
        );

        return Promise.resolve();
      } catch (error: any) {
        return Promise.reject(error);
      }
    },
    custom: async ({ meta, url }) => {
      const { leagueId, seasonId } = meta as {
        leagueId: number;
        seasonId: string;
      };

      const getUrl = `cms/rs/${url}`;

      const axiosResponse = await httpClient.get(
        `${getUrl}?${qs.stringify({
          leagueId,
          seasonId,
        })}`
      );

      const { data } = axiosResponse;

      return Promise.resolve({ data });
    },
  };
